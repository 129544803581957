import React from "react";
import { createRoot } from "react-dom/client";
import { Root } from "./components/Root";
import UserProvider from "./providers/UserProvider";
import ConfigProvider from "./providers/ConfigProvider";
import "react-datepicker/dist/react-datepicker.css";
import "react-dropdown-tree-select/dist/styles.css";
import "react-dropzone-uploader/dist/styles.css";
import { updateConfig } from "./config";

async function render() {
  // Find all the elements that need React to render a component
  const itjNgReactSpots = Array.prototype.slice.call(
    document.querySelectorAll(".jobs-react")
  );

  // Only move on with anything if there are actually components to render
  if (itjNgReactSpots.length) {
    const userElement = document.getElementById("jobs-react--user");
    const userAttribute = userElement?.getAttribute("user");
    const user = userAttribute ? JSON.parse(userAttribute) : undefined;
    const configElement = document.getElementById("jobs-react--config");
    const configAttribute = configElement?.getAttribute("config");
    const config = configAttribute ? JSON.parse(configAttribute) : null;
    updateConfig(config);

    // Create a react root element we'll render into. Note that this is just used to anchor our React tree in an
    // arbitraty place since all our actual UI components will be rendered into their own containers through portals.
    const reactRootElement = document.createElement("div");
    reactRootElement.setAttribute("class", "jobs-react jobs-react--root");
    document.body.append(reactRootElement);
    const root = createRoot(reactRootElement);

    // Render the tree inside a shared `IntlProvider` so all components are able to access translated strings.
    root.render(
      <React.StrictMode>
        <ConfigProvider config={config}>
          <UserProvider user={user}>
            <Root itjNgReactSpots={itjNgReactSpots} />
          </UserProvider>
        </ConfigProvider>
      </React.StrictMode>
    );
  }
}

document.addEventListener("DOMContentLoaded", render);
