import styled from "styled-components";

const TagCloseButton = styled.div`
  box-sizing: border-box;
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  margin: 3px;
  right: 0;
  top: 0;
  padding: 0px;
  background-color: white;
  border-radius: 60px;
  z-index: 100;

  // breakpoint(large)
  @media (min-width: 68.375em) {
    width: 29px;
    height: 29px;
    margin: 4px;
    padding: 3px;
  }

  &:hover,
  &:active {
    transition: background-color 0.3s ease;
    fill: var(--button-color);
  }
`;

const Tag = styled.div`
  display: block;
  padding: 6px 45px 6px 14px;
  background-color: var(--background-grey);
  position: relative;
  border-radius: 60px;
  box-sizing: border-box;
  text-decoration: none;
  color: black;

  // breakpoint(large)
  @media (min-width: 68.375em) {
    padding: 9px 45px 9px 14px;
  }

  &:hover,
  &:active {
    box-sizing: border-box;
    box-shadow: inset 0px 0px 0px 1px var(--line-grey);
    transition: background-color 0.3s ease;
    cursor: pointer;
  }
`;

const Styled = {
  Tag,
  TagCloseButton,
};

export default Styled;
