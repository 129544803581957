import React, { useCallback } from "react";
import InputBase from "./InputBase";

interface InputProps {
  label?: string;
  value?: string | number;
  type?: string;
  className?: string;
  placeholder?: string;
  readonly?: boolean;

  onValueUpdate?(value: string | undefined): void;

  onEnter?(): void;
}

const Input: React.FC<InputProps> = ({
  label,
  value,
  type,
  className,
  placeholder,
  readonly,
  onValueUpdate,
  onEnter,
}) => {
  const handleValueUpdate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onValueUpdate && onValueUpdate(e.target.value || "");
    },
    [onValueUpdate]
  );

  const handleEnter = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.code === "Enter" && onEnter) {
        onEnter();
      }
    },
    [onEnter]
  );

  return (
    <InputBase label={label}>
      <input
        type={type || "text"}
        value={value}
        name="search"
        className={className}
        placeholder={placeholder}
        onChange={handleValueUpdate}
        readOnly={readonly}
        onKeyUp={handleEnter}
      />
    </InputBase>
  );
};

export default Input;
