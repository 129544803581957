import styled from "styled-components";

const JobSimilarList = styled.div`
  box-sizing: border-box;
  background-color: white;
  padding: 10px 8px;
  box-sizing: border-box;

  // breakpoint(xsmall)
  @media (min-width: 34.5em) {
    padding: 10px 25px;
  }
`;

const Styled = {
  JobSimilarList,
};

export default Styled;
