import get from "lodash-es/get";
import startCase from "lodash-es/startCase";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { hydrateRoot } from "react-dom/client";
import CompanyListRoot from "./company/list/CompanyListRoot";
import JobPremiumRoot from "./job/premium/JobPremiumRoot";
import JobAllRoot from "./job/all/JobAllRoot";
import JobDetailsRoot from "./job/details/JobDetailsRoot";
import JobSimilarRoot from "./job/similar/JobSimilarRoot";
import JobSubmissionFormRoot from "./job-submission/form/JobSubmissionFormRoot";
import JobMailerSubscriptionRoot from "./job-mailer/subscription/JobMailerSubscriptionRoot";
import JobMailerProfileRoot from "./job-mailer/profile/JobMailerProfileRoot";
import TopEmployersWidget from "./widgets/TopEmployersWidget";
import AdvertisementBannerWidget from "./widgets/AdvertisementBannerWidget";
import JobsAggregationsWidget from "./widgets/JobsAggregationsWidget";

// List the top-level components that can be directly called from the Django templates in an interface
// for type-safety when we call them. This will let us use the props for any top-level component in a
// way TypeScript understand and accepts
interface ComponentLibrary {
  CompanyListRoot: typeof CompanyListRoot;
  JobPremiumRoot: typeof JobPremiumRoot;
  JobAllRoot: typeof JobAllRoot;
  JobDetailsRoot: typeof JobDetailsRoot;
  JobSimilarRoot: typeof JobSimilarRoot;
  JobSubmissionFormRoot: typeof JobSubmissionFormRoot;
  JobMailerSubscriptionRoot: typeof JobMailerSubscriptionRoot;
  JobMailerProfileRoot: typeof JobMailerProfileRoot;
  TopEmployersWidget: typeof TopEmployersWidget;
  AdvertisementBannerWidget: typeof AdvertisementBannerWidget;
  JobsAggregationsWidget: typeof JobsAggregationsWidget;
}

// Actually create the component map that we'll use below to access our component classes
const componentLibrary: ComponentLibrary = {
  CompanyListRoot,
  JobPremiumRoot: JobPremiumRoot,
  JobAllRoot: JobAllRoot,
  JobDetailsRoot: JobDetailsRoot,
  JobSimilarRoot: JobSimilarRoot,
  JobSubmissionFormRoot: JobSubmissionFormRoot,
  JobMailerSubscriptionRoot: JobMailerSubscriptionRoot,
  JobMailerProfileRoot: JobMailerProfileRoot,
  TopEmployersWidget: TopEmployersWidget,
  AdvertisementBannerWidget: AdvertisementBannerWidget,
  JobsAggregationsWidget: JobsAggregationsWidget,
};

// Type guard: ensures a given string (candidate) is indeed a proper key of the componentLibrary with a corresponding
// component. This is a runtime check but it allows TS to check the component prop types at compile time
function isComponentName(
  candidate: keyof ComponentLibrary | string
): candidate is keyof ComponentLibrary {
  return Object.keys(componentLibrary).includes(candidate);
}

interface RootProps {
  itjNgReactSpots: Element[];
}

export const Root: React.FC<RootProps> = ({ itjNgReactSpots }) => {
  const portals = itjNgReactSpots.map((element: Element) => {
    // Generate a component name. It should be a key of the componentLibrary object / ComponentLibrary interface
    const componentName = startCase(
      get(element.className.match(/jobs-react--([a-zA-Z-]*)/), "[1]") || ""
    )
      .split(" ")
      .join("");

    // Sanity check: only attempt to access and render components for which we do have a valid name
    if (isComponentName(componentName)) {
      // Do get the component dynamically. We know this WILL produce a valid component thanks to the type guard
      const Component = componentLibrary[componentName];

      let props: any = {};

      // Get the props to pass our components from the `data-props-source` if
      const dataPropsSource = element.getAttribute("data-props-source");
      if (dataPropsSource) {
        props = JSON.parse(
          document.querySelector(dataPropsSource)!.textContent!
        );
      }

      // Get the incoming props to pass our component from `data-props` if applicable
      const dataProps = element.getAttribute("data-props");
      if (dataProps) {
        props = { ...props, ...JSON.parse(dataProps) };
      }

      if (!element.innerHTML) {
        return ReactDOM.createPortal(<Component {...props} />, element);
      } else {
        hydrateRoot(element, <Component {...props} />);
        return null;
      }
    } else {
      // Emit a warning at runtime when we fail to find a matching component for an element that required one
      console.warn(
        "Failed to load React component: no such component in Library " +
          componentName
      );
      return null;
    }
  });

  return <Suspense fallback="Loading...">{portals}</Suspense>;
};
