import React from "react";
import { formatDateDots } from "../../utils/date-utils";
import { Job } from "./types";

interface JobBoxProps {
  job: Job;
}

const JobBox: React.FC<JobBoxProps> = ({ job }) => {
  return (
    <div className="singlebox">
      <div className="imgtext">
        <a href={`/jobs/${job.seoTitle || ""}/${job.id}/`}>
          <picture className="customerlogo">
            <img
              src={job.accountId ? `/companies/image/${job.accountId}.png` : undefined}
              alt={job.company?.name ? `${job.company.name} logo` : "Company logo"}
              width="90"
            />
          </picture>
        </a>
        <article>
          <h2>
            <a href={`/jobs/${job.seoTitle || ""}/${job.id}/`}>
              {job.title || ""}
            </a>
          </h2>
          <p>
            {job.workplace && job.workplace.city
              ? job.workplace?.city + ", "
              : ""}
            {formatDateDots(job.publishing?.from) || ""}
          </p>
        </article>
      </div>
    </div>
  );
};

export default JobBox;
