import styled from "styled-components";

const InputBaseLabel = styled.label`
  font-weight: 700;
  padding-left: 15px;
  transition: all 0.2s;
  touch-action: manipulation;
  color: #585858;
`;

const InputBaseField = styled.div`
  display: flex;
  flex-flow: column-reverse;
`;

const InputBase = styled.div`
  & > ${InputBaseField} input {
    height: 40px;
    font-size: 14px;
    border: 2px solid black;
  }

  & > ${InputBaseField} input:focus {
    outline: 0;
    border: 2px solid var(--theme-color);
  }

  & > ${InputBaseField} > textarea {
    outline: none;
    resize: vertical;
    border: 2px solid black;
    transition: all 0.2s;
    touch-action: manipulation;
    padding: 15px;
    font-size: 14px;
    font-family: inherit;
  }

  & > ${InputBaseField} > textarea:focus {
    border-color: #9e020c;
  }

  // Styles for integration with react-datepicker
  &
    > ${InputBaseField}
    > .react-datepicker-wrapper
    > .react-datepicker__input-container
    > input {
    height: 40px;
    font-size: 16px;
    width: 100%;
  }

  .checkbox {
    -webkit-appearance: checkbox;
  }
`;

const Styled = {
  InputBase,
  InputBaseField,
  InputBaseLabel,
};

export default Styled;
