import React, { useCallback } from "react";
import SvgIcon from "../SvgIcon";
import Styled from "./styled";

interface TagProps {
  name: string;

  onTagClick(name: string): void;

  onTagClose(name: string): void;
}

const Tag: React.FC<TagProps> = ({ name, onTagClick, onTagClose }) => {
  const handleTagClick = useCallback(() => {
    onTagClick(name);
  }, [name, onTagClick]);

  const handleTagClose = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      onTagClose(name);
    },
    [name, onTagClose]
  );

  return (
    <Styled.Tag onClick={handleTagClick}>
      {name}
      <Styled.TagCloseButton onClick={handleTagClose}>
        <SvgIcon name="close" />
      </Styled.TagCloseButton>
    </Styled.Tag>
  );
};

export default Tag;
