import React, { PropsWithChildren } from "react";
import Styled from "./styled";

interface InputBaseProps extends PropsWithChildren<{}> {
  label?: string;
}

const InputBase: React.FC<InputBaseProps> = ({ label, children }) => {
  return (
    <Styled.InputBase>
      <Styled.InputBaseField>
        {children}
        {label && <Styled.InputBaseLabel>{label}</Styled.InputBaseLabel>}
      </Styled.InputBaseField>
    </Styled.InputBase>
  );
};

export default InputBase;
