import styled from "styled-components";

const Letter = styled.div<{ selected?: boolean }>`
  display: inline-block;
  flex: 1;
  margin: 1px;
  padding: 10px 3px;
  cursor: pointer;
  background-color: #cbcbcb;
  color: black;
  min-width: 30px;
  transition: background-color 0.3s ease;
  font-weight: ${(props) => (props.selected ? "bold" : "normal")};

  &:hover {
    background-color: white;
  }
`;

const Alphabet = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 45px 0 25px 0;
  text-align: center;
  width: 100%;
`;

const Styled = {
  Alphabet,
  Letter,
};

export default Styled;
