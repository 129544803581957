import React, { useContext } from "react";
import { config } from "../../config";
import { ConfigContext } from "../../providers/ConfigProvider";
import Styled from "./styled";

declare let django_privacy_mgmt: any;

declare let googletag: any;

const AdvertisementBannerWidget: React.FC = () => {
  const advertisementGPT = useContext(ConfigContext)?.advertisementGPT || config?.advertisementGPT;
  const showAds = typeof django_privacy_mgmt !== "undefined" && django_privacy_mgmt && django_privacy_mgmt.getPreference("MARKETING");

  return (
    <>
      {showAds && (
        <Styled.AdvertisementBannerWidget>
          <div id={advertisementGPT}>
            <script>
              {
                googletag.cmd.push(function () {
                  googletag.display(advertisementGPT);
                })
              }
            </script>
          </div>
        </Styled.AdvertisementBannerWidget>
      )}
    </>
  );
};

export default AdvertisementBannerWidget;
