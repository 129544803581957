import React from "react";
import { JobMailerSubscriptionStoreProvider } from "./JobMailerSubscriptionStore";
import JobMailerSubscriptionPage from "./JobMailerSubscriptionPage";

interface JobMailerSubscriptionRootProps {
  jobId: number | null;
  jobMailerUrl: string;
}

const JobMailerSubscriptionRoot: React.FC<JobMailerSubscriptionRootProps> = ({
  jobId,
  jobMailerUrl,
}) => {
  return (
    <JobMailerSubscriptionStoreProvider
      jobId={jobId}
      jobMailerUrl={jobMailerUrl}
    >
      <JobMailerSubscriptionPage />
    </JobMailerSubscriptionStoreProvider>
  );
};

export default JobMailerSubscriptionRoot;
