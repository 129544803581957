import React from "react";
import Wrapper from "../../common/Wrapper";
import Styled from "./styled";

interface SearchNoImageProps {}

const SearchNoImage: React.FC<SearchNoImageProps> = () => {
  return (
    <Wrapper>
      <Styled.SearchBackground />
    </Wrapper>
  );
};

export default SearchNoImage;
