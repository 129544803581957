import { format, parseISO } from "date-fns";

export const formatDate = (
  date: Date | undefined | null
): string | undefined => {
  return !!date ? format(date, "yyyy-MM-dd") : undefined;
};

export const formatDateDots = (
  date: string | undefined
): string | undefined => {
  return date !== undefined ? format(parseISO(date), "dd.MM.yyyy") : undefined;
};

export const getToday = (): Date => {
  return new Date();
};

export const get1YearAhead = (): Date => {
  const date = new Date();
  date.setFullYear(date.getFullYear() + 1);
  return date;
};
