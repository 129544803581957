import React from "react";
import { observer } from "mobx-react";
import { useJobMailerSubscriptionStore } from "./JobMailerSubscriptionStore";
import ThreeColumnLayout from "../../common/layout/ThreeColumnLayout";
import JobMailerSubscriptionForm from "./JobMailerSubscriptionForm";
import TopEmployersWidget from "../../widgets/TopEmployersWidget";
import AdvertisementBannerWidget from "../../widgets/AdvertisementBannerWidget";

const JobMailerSubscriptionPage = observer(() => {
  const store = useJobMailerSubscriptionStore();
  const {
    jobId,
    jobMailerUrl,
    email,
    error,
    showSubscriptionMessage,
    firstTimeSending,
    withJob,
    setEmail,
    validate,
    sendToken,
  } = store;

  return (
    <ThreeColumnLayout
      left={null}
      mid={
        <JobMailerSubscriptionForm
          email={email}
          error={error}
          showSubscriptionMessage={showSubscriptionMessage}
          firstTimeSending={firstTimeSending}
          withJob={withJob}
          jobId={jobId}
          jobMailerUrl={jobMailerUrl}
          updateEmail={setEmail}
          validate={validate}
          onSubmit={sendToken}
        />
      }
      right={
        <>
          <TopEmployersWidget />
          <AdvertisementBannerWidget />
        </>
      }
    />
  );
});

export default JobMailerSubscriptionPage;
