import React from "react";
import JobDetailsPage from "./JobDetailsPage";
import { JobDetailsStoreProvider } from "./JobDetailsStore";
import OneColumnLayout from "../../common/layout/OneColumnLayout";

interface JobRootProps {
  data: any;
}

const JobDetailsRoot: React.FC<JobRootProps> = ({ data }) => {
  return (
    <>
      {data.job && (
        <JobDetailsStoreProvider data={data}>
          <JobDetailsPage />
        </JobDetailsStoreProvider>
      )}
      {!data.job && (
        <OneColumnLayout>
          <div className="center" style={{ marginTop: "60px" }}>
            <h2>
              Job ist nicht mehr verfügbar oder wurde noch nicht veröffentlicht
            </h2>
          </div>
        </OneColumnLayout>
      )}
    </>
  );
};

export default JobDetailsRoot;
