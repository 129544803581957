import React from "react";
import { formatDateDots } from "../../../utils/date-utils";
import { Job } from "../types";
import Styled from "./styled";

interface JobListItemProps {
  job: Job;
}

const JobListItem: React.FC<JobListItemProps> = ({ job }) => {
  return (
    <Styled.JobListItem>
      {job.accountId && (
        <Styled.JobListItemImage>
          <a href={`/jobs/${job.seoTitle || ""}/${job.id}/`}>
            <picture>
              <img
                src={job.accountId ? `/companies/image/${job.accountId}.png` : undefined}
                alt={job.company?.name ? `${job.company.name} logo` : "Company logo"}
                width="90"
              />
            </picture>
          </a>
        </Styled.JobListItemImage>
      )}
      <Styled.JobListItemInfo>
        <Styled.JobListItemInfoName>
          <a href={`/jobs/${job.seoTitle || ""}/${job.id}/`}>
            {job.title || ""}
          </a>
        </Styled.JobListItemInfoName>
      </Styled.JobListItemInfo>
      {(job?.workplace?.city || job?.publishing?.from) && (
        <Styled.JobListItemLocation>
          {job?.workplace?.city && (
            <>
              {job.workplace.city}
              <br />
            </>
          )}
          {job?.publishing?.from && (
            <>{formatDateDots(job.publishing?.from)}</>
          )}
        </Styled.JobListItemLocation>
      )}
    </Styled.JobListItem>
  );
};

export default JobListItem;
