import React from "react";
import axios from "axios";
import { action, observable, makeObservable, computed } from "mobx";
import { Job, JobAggregations } from "../types";
import { createAggregations } from "../utils";
import { Node } from "../../types";

const defaultJobAggregations: JobAggregations = {
  categories: [],
};

export class JobSimilarStore {
  private readonly jobId: number;
  @observable jobs?: Job[];
  @observable aggregations: JobAggregations = defaultJobAggregations;

  constructor(jobId: number) {
    makeObservable(this);
    this.jobId = jobId;
    this.init();
  }

  readonly init = () => {
    this.loadSimilarJobs();
  };

  @action readonly loadSimilarJobs = () => {
    const jobId = this.jobId;
    axios.get("/jobs-api/similar/", { params: { jobId } }).then(
      action((response) => {
        this.jobs = response.data.list;
        this.aggregations = createAggregations(response.data.aggregations);
      })
    );
  };

  @computed get aggregationsList(): Node[] {
    const nodes: Node[] = [];
    const { aggregations } = this;
    if (aggregations.categories && aggregations.categories.length > 0) {
      nodes.push({
        label: "Category",
        fullname: "Category",
        showSubnodes: true,
        subnodes: aggregations.categories,
      });
    }
    return nodes;
  }
}

const storeContext = React.createContext<JobSimilarStore | null>(null);

export const JobSimilarStoreProvider = ({
  jobId,
  children,
}: React.PropsWithChildren<{ jobId: number }>) => {
  const store = new JobSimilarStore(jobId);
  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
};

export const useJobSimilarStore = () => {
  const store = React.useContext(storeContext);
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error(
      "useJobSimilarStore must be used within a JobSimilarStoreProvider."
    );
  }
  return store;
};
