import styled from "styled-components";

const CompanyProfileList = styled.div`
  box-sizing: border-box;
  background-color: white;
  padding: 10px 8px;

  // breakpoint(xsmall)
  @media (min-width: 34.5em) {
    padding: 10px 25px;
  }

  .lopri .imgtextplace,
  .lopri article,
  .lopri .place {
    opacity: 0.5;
  }

  box-sizing: border-box;

  .imgtextplace,
  .imgtextplace:visited,
  .imgtextplace:active,
  .imgtextplace:focus {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 20px 0px;
    text-decoration: none;
    color: black;

    // breakpoint(large)
    @media (min-width: 68.375em) {
      padding: 20px 20px;
    }

    border-bottom: 1px solid var(--line-grey);

    align-items: center;
    picture {
      padding: 0;
      margin: 0;
      align-self: center;
    }

    article {
      flex: 1;
      padding-left: 20px;

      h2 {
        font-size: 1.1em;
        padding: 0px 0 5px 0;
        margin: 0;

        a,
        a:visited {
          text-decoration: none;
        }

        a:hover {
          text-decoration: underline;
        }
      }

      p {
        font-size: 0.9em;
        line-height: 1.6;
        padding: 0;
        margin: 0;
        color: var(--font-grey);

        span {
          display: inline;

          // breakpoint(xsmall)
          @media (min-width: 34.5em) {
            display: none;
          }
        }
      }
    }

    .place {
      display: none;
      align-self: flex-start;

      // breakpoint(xsmall)
      @media (min-width: 34.5em) {
        display: block;
      }

      width: 140px;
      text-align: right;
      font-size: 0.9em;
    }

    .simple {
      opacity: 0.5;
    }
  }

  .imgtextplace.not-published {
    background-color: rgba(255, 0, 0, 0.3);
  }

  .imgtextplace:last-child {
    border-bottom: none;
  }
`;

const Styled = {
  CompanyProfileList,
};

export default Styled;
