import React, { useCallback } from "react";
import InputBase from "./InputBase";

interface TextareaProps {
  label?: string;
  value?: string;
  className?: string;

  onValueUpdate?(value: string | undefined): void;
}

const Textarea: React.FC<TextareaProps> = ({
  label,
  value,
  className,
  onValueUpdate,
}) => {
  const handleValueUpdate = useCallback(
    (e: any) => {
      onValueUpdate && onValueUpdate(e.target.value || "");
    },
    [onValueUpdate]
  );

  return (
    <InputBase label={label}>
      <textarea
        name="search"
        className={className}
        value={value}
        onChange={handleValueUpdate}
      />
    </InputBase>
  );
};

export default Textarea;
