import styled from "styled-components";

const Wrapper = styled.div`
  padding: 0 8px;

  // breakpoint(xlarge)
  @media (min-width: 80.375em) {
    padding: 0 25px;
  }
`;

const Styled = {
  Wrapper,
};

export default Styled;
