import React, { useCallback, useState } from "react";
import Modal from "../../common/Modal";
import axios from "axios";
import { getCookie } from "../../../utils/cookie-utils";
import Input from "../../common/Input/Input";
import Textarea from "../../common/Input/Textarea";
import { formatDate, get1YearAhead, getToday } from "../../../utils/date-utils";
import DatePicker from "react-datepicker";
import InputBase from "../../common/Input/InputBase";
import Checkbox from "../../common/Input/Checkbox";

interface CompanyCreationModalProps {
  close(): void;
}

const CompanyCreationModal: React.FC<CompanyCreationModalProps> = ({
  close,
}) => {
  const [name, setName] = useState<string>();
  const [location, setLocation] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [publicationDate, setPublicationDate] = useState<Date>(getToday());
  const [publicationEndDate, setPublicationEndDate] = useState<Date>(
    get1YearAhead()
  );
  const [fullProfile, setFullProfile] = useState<boolean>(true);
  const [vmsAccountId, setVmsAccountId] = useState<string>();
  const [vmsBookingId, setVmsBookingId] = useState<string>();

  const [error, setError] = useState<string>();

  const handleNameUpdate = useCallback(
    (name: string) => {
      setName(name);
      if (!!error) {
        setError(undefined);
      }
    },
    [error]
  );

  const handlePublicationDateUpdate = useCallback((date: Date) => {
    setPublicationDate(date);
  }, []);

  const handlePublicationEndDateUpdate = useCallback((date: Date) => {
    setPublicationEndDate(date);
  }, []);

  const handleFullProfileUpdate = useCallback((state: boolean) => {
    setFullProfile(state);
    if (!state) {
      setVmsBookingId(undefined);
    }
  }, []);

  const handleSave = useCallback(() => {
    const csrftoken = getCookie("csrftoken");
    axios
      .post(
        "/companies/create/",
        {
          name: name || undefined,
          location: location || undefined,
          description: description || undefined,
          publicationDate: formatDate(publicationDate),
          publicationEndDate: formatDate(publicationEndDate),
          fullProfile: fullProfile,
          vmsAccountId: vmsAccountId || undefined,
          vmsBookingId: vmsBookingId || undefined,
        },
        csrftoken
          ? {
              headers: {
                "X-CSRFToken": csrftoken,
              },
            }
          : undefined
      )
      .then((response) => {
        close();
      })
      .catch((error) => {
        setError(error.response.data.message);
      });
  }, [
    name,
    location,
    description,
    publicationDate,
    publicationEndDate,
    fullProfile,
    vmsAccountId,
    vmsBookingId,
    close,
  ]);

  const handleCancel = useCallback(() => {
    close();
  }, [close]);

  return (
    <Modal
      title="Firma hinzufügen"
      primaryDisabled={
        !name ||
        !location ||
        (fullProfile && !description) ||
        !publicationDate ||
        !publicationEndDate ||
        !vmsAccountId ||
        (fullProfile && !vmsBookingId) ||
        (!fullProfile && !!vmsBookingId)
      }
      onPrimaryClick={handleSave}
      onSecondaryClick={handleCancel}
    >
      <Input label="Name" value={name} onValueUpdate={handleNameUpdate} />
      <Input label="Location" value={location} onValueUpdate={setLocation} />
      <Textarea
        label="Kurze Beschreibung"
        value={description}
        onValueUpdate={setDescription}
      />
      <InputBase label="Erscheinungsdatum">
        <DatePicker
          selected={publicationDate}
          onChange={handlePublicationDateUpdate}
          minDate={getToday()}
          maxDate={publicationEndDate}
          dateFormat="dd.MM.yyyy"
          showYearDropdown
        />
      </InputBase>
      <InputBase label="Enddatum der Veröffentlichung">
        <DatePicker
          selected={publicationEndDate}
          onChange={handlePublicationEndDateUpdate}
          minDate={publicationDate}
          dateFormat="dd.MM.yyyy"
          showYearDropdown
        />
      </InputBase>
      <Checkbox
        label="Full profile"
        checked={fullProfile}
        onValueUpdate={handleFullProfileUpdate}
      />
      <Input
        type="number"
        label="VMS Account ID"
        value={vmsAccountId}
        onValueUpdate={setVmsAccountId}
      />
      {fullProfile && (
        <Input
          type="number"
          label="VMS Booking ID"
          value={vmsBookingId}
          onValueUpdate={setVmsBookingId}
        />
      )}
      {!!error && <div className="error-msg">{error}</div>}
    </Modal>
  );
};

export default CompanyCreationModal;
