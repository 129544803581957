import styled from "styled-components";

const JobMailerProfileFormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid gray;

  @media (max-width: 720px) {
    display: inherit;
    border-bottom: 1px solid gray;

    h4 {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
`;

const JobMailerProfileFormContent = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;

  input {
    height: 25px;
  }
`;

const JobMailerProfileFormFooter = styled.div`
  margin-bottom: 15px;
`;

const JobMailerProfileFormFooterLink = styled.div`
  display: flex;
  justify-content: center;
  border-top: 1px solid gray;
  margin-bottom: 15px;
  padding-top: 5px;
  font-size: 11px;

  &:hover {
    cursor: pointer;
  }
`;

const JobMailerProfileForm = styled.div`
  padding: 10px 30px;
  background-color: white;

  .alert {
    margin-bottom: 0;
  }
`;

const Styled = {
  JobMailerProfileForm,
  JobMailerProfileFormHeader,
  JobMailerProfileFormContent,
  JobMailerProfileFormFooter,
  JobMailerProfileFormFooterLink,
};

export default Styled;
