import styled from "styled-components";

const OneColumnLayout = styled.div`
  max-width: 1625px;
  margin: 0 auto;

  line-height: 180%;

  h1 {
    font-size: 1.2em;
    padding: 15px 0 10px 0;

    // breakpoint(large)
    @media (min-width: 68.375em) {
      padding: 55px 0 35px 0;
      font-size: 1.6em;
    }
  }

  h2 {
    font-size: 1em;
    padding: 0 0 4px 0;
    margin: 0;

    // breakpoint(large)
    @media (min-width: 68.375em) {
      font-size: 1.2em;
      padding: 0 0 6px 0;
    }
  }

  p {
    margin: 0;
  }

  hr {
    border-top: 2px solid var(--line-grey);
    margin: 30px 0 10px 0;
  }

  .total {
    font-size: 0.85em;
    display: inline-block;
    width: 100%;

    span {
      font-size: 1.4em;
      font-weight: bold;
    }

    .alright {
      text-align: right;
      float: right;
    }
  }

  .floater {
    font-size: 0.85em;

    // breakpoint(large)
    @media (min-width: 68.375em) {
      font-size: 1em;
    }

    .alert {
      white-space: pre-line;
      margin-top: 0;
    }

    .right {
      background-color: white;
      padding: 20px;
      margin-bottom: 25px;

      // breakpoint(large)
      @media (min-width: 68.375em) {
        width: 48%;
        float: right;
      }
    }

    .left {
      .flexcon {
        display: flex;
        width: 100%;

        .one {
          width: 120px;
          flex-shrink: 0;

          // breakpoint(large)
          @media (min-width: 68.375em) {
            width: 270px;
          }

          .inputwrapper {
            position: relative;

            svg {
              position: absolute;
              top: 17px;
              right: 8px;
              cursor: pointer;
            }
          }
        }

        .two {
          width: 50px;

          // breakpoint(large)
          @media (min-width: 68.375em) {
            width: 70px;
          }
          text-align: center;
          flex-shrink: 0;
          padding-top: 102px;
        }

        .three {
          flex: 1;
          flex-wrap: wrap;

          .url {
            width: 100%;
            flex-wrap: wrap;
            float: left;
          }

          input {
            flex-wrap: wrap;
            float: left;
          }

          ::placeholder {
            font-weight: bold;
            color: black;
          }
        }

        .greybtn {
          font-size: 0.9em;
          height: 45px;
          display: inline-block;
          line-height: 1.2;
        }
      }

      input,
      textarea {
        font-size: 0.9em;
        height: 45px;
        display: block;
        line-height: 1.2;
        background-color: white;
        border: 1px solid black;
        font-family: "Raleway", sans-serif;
        text-indent: 10px;
        width: 100%;
      }

      textarea {
        height: 100px;
      }

      .tobospace {
        margin: 7px 0;
      }

      .field {
        margin-bottom: 0;
      }

      .hide {
        position: absolute;
        left: -999999px;
      }

      // breakpoint(large)
      @media (min-width: 68.375em) {
        width: 48%;
        float: left;
      }
    }
  }
`;

const Styled = {
  OneColumnLayout,
};

export default Styled;
