export const getWindowObj = (): typeof window | undefined => {
  if (typeof window !== "undefined") {
    return window;
  } else {
    return undefined;
  }
};

export const getFullHeight = (): number => {
  const body = document.body;
  const html = document.documentElement;

  return Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  );
};
