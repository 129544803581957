import styled from "styled-components";

const PaginationLoadMore = styled.div`
  margin-top: 25px;
  margin-bottom: 15px;
`;

const Styled = {
  PaginationLoadMore,
};

export default Styled;
