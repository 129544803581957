import styled from "styled-components";

const ThreeColumnLayout = styled.div`
  display: flex;
  max-width: 1625px;
  margin: 0 auto;

  @media (max-width: 1024px) {
    display: block;
  }
`;

const ThreeColumnLayoutLeft = styled.div`
  @media (min-width: 1024px) {
    display: block;
    padding-top: 80px;
    width: 300px;
    box-sizing: border-box;
  }
`;

const ThreeColumnLayoutMid = styled.div`
  flex: 1;
  box-sizing: border-box;

  @media (min-width: 1024px) {
    padding: 20px 20px 0 20px;
  }

  @media (max-width: 1024px) {
    padding: 0 !important;
  }
`;

const ThreeColumnLayoutRight = styled.div`
  @media (min-width: 1024px) {
    display: block;
    padding-top: 80px;
    width: 300px;
    box-sizing: border-box;
  }
`;

const Styled = {
  ThreeColumnLayout,
  ThreeColumnLayoutLeft,
  ThreeColumnLayoutMid,
  ThreeColumnLayoutRight,
};

export default Styled;
