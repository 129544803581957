import React from "react";
import JobDetailsContentHeader from "./JobDetailsContentHeader";
import JobDetailsDescription from "./JobDetailsDescription";
import JobDetailsContacts from "./JobDetailsContacts";
import { JobDetails } from "../types";

interface JobDetailsContentProps {
  job: JobDetails;
  jobMailerUrl: string;
  companyProfileUrl: string;
  similarJobsUrl: string;
}

const JobDetailsContent: React.FC<JobDetailsContentProps> = ({
  job,
  jobMailerUrl,
  companyProfileUrl,
  similarJobsUrl
}) => {
  return (
    <>
      <JobDetailsContentHeader job={job} />
      <JobDetailsDescription job={job} companyProfileUrl={companyProfileUrl} />
      <JobDetailsContacts
        jobId={job.id}
        jobMailerUrl={jobMailerUrl}
        applicationUrlInfo={job.applicationUrlInfo}
        similarJobsUrl={similarJobsUrl}
      />
    </>
  );
};

export default JobDetailsContent;
