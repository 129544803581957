import React from "react";
import Styled from "./styled";

interface CompanyProfileListProps {
  companies: any[];
}

const CompanyProfileList: React.FC<CompanyProfileListProps> = ({
  companies,
}) => {
  return (
    <Styled.CompanyProfileList>
      {companies.length > 0 &&
        companies.map((company) => (
          <a
            className={`imgtextplace ${
              company.published ? "" : "not-published"
            }`}
            href={`${company.slug}/`}
          >
            <picture className="customerlogo">
              <img
                src={`${window.location.origin}/${company.imageUrl}`}
                alt={company.shortName ? `${company.shortName} logo` : "Company logo"}
                width="90"
              />
            </picture>
            <article className={!company.fullProfile ? "simple" : ""}>
              <h2>{company.shortName}</h2>
              <p>{company.shortDescription}</p>
            </article>
            <div className={`place ${!company.fullProfile ? "simple" : ""}`}>
              <p>
                <strong>{company.location}</strong>
              </p>
            </div>
          </a>
        ))}
      {companies.length === 0 && (
        <p className="center">No companies are found.</p>
      )}
    </Styled.CompanyProfileList>
  );
};
export default CompanyProfileList;
