import React from "react";
import { JobAllStoreProvider } from "./JobAllStore";
import JobAllPage from "./JobAllPage";

interface JobAllRootProps {
  classificationSlug: string | null;
  regionSlug: string | null;
  jobsPerPage: number;
}

const JobAllRoot: React.FC<JobAllRootProps> = ({
  classificationSlug,
  regionSlug,
  jobsPerPage,
}) => {
  return (
    <JobAllStoreProvider
      classificationSlug={classificationSlug}
      regionSlug={regionSlug}
      jobsPerPage={jobsPerPage}
    >
      <JobAllPage />
    </JobAllStoreProvider>
  );
};

export default JobAllRoot;
