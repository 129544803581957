import React, { useContext } from "react";
import Wrapper from "../../common/Wrapper";
import { ConfigContext } from "../../../providers/ConfigProvider";
import Styled from "./styled";

interface SearchImageProps {}

const SearchImage: React.FC<SearchImageProps> = () => {
  const portal = useContext(ConfigContext)?.instance;

  return (
    <Wrapper>
      <Styled.SearchImageMobile>
        <Styled.SearchImageMobileImage
          src={`/static/img/headerphoto/mob/${portal}1.jpg`}
        />
      </Styled.SearchImageMobile>
      <Styled.SearchImageDesktop>
        <Styled.SearchImageDesktopImage
          src={`/static/img/headerphoto/${portal}1.jpg`}
        />
      </Styled.SearchImageDesktop>
    </Wrapper>
  );
};

export default SearchImage;
