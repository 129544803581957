import React, { PropsWithChildren } from "react";
import Button from "../Button";
import Styled from "./styled";

interface ModalProps extends PropsWithChildren<{}> {
  title: string;
  primary?: string;
  secondary?: string;
  primaryDisabled?: boolean;
  wide?: boolean;

  onPrimaryClick?(): void;

  onSecondaryClick?(): void;
}

const Modal: React.FC<ModalProps> = ({
  title,
  primary,
  secondary,
  primaryDisabled,
  wide,
  onPrimaryClick,
  onSecondaryClick,
  children,
}) => {
  return (
    <Styled.Modal>
      <Styled.ModalInner wide={wide}>
        <Styled.ModalHeader>
          <Styled.ModalTitle>{title}</Styled.ModalTitle>
        </Styled.ModalHeader>
        <Styled.ModalBody>{children}</Styled.ModalBody>
        <Styled.ModalFooter>
          <Button
            label={secondary || "Stornieren"}
            onClick={onSecondaryClick}
          />
          <Button
            label={primary || "Einreichen"}
            onClick={onPrimaryClick}
            primary
            disabled={primaryDisabled}
          />
        </Styled.ModalFooter>
      </Styled.ModalInner>
    </Styled.Modal>
  );
};

export default Modal;
