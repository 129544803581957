import React from "react";
import Styled from "./styled";

interface SvgIconProps {
  name: string;
}

const SvgIcon: React.FC<SvgIconProps> = ({ name }) => {
  return (
    <Styled.SvgIcon>
      <use xlinkHref={`#${name}`} />
    </Styled.SvgIcon>
  );
};

export default SvgIcon;
