import styled, { css } from "styled-components";

const Tabs = styled.div`
  overflow: hidden;
  text-align: center;
  margin-top: 0px;

  // breakpoint(large) {
  @media (min-width: 68.375em) {
    margin-top: 30px;
  }
`;

const TabSelector = styled.button<{ selected?: boolean }>`
  background-color: inherit;
  display: inline-block;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 9px 16px;
  margin: 0;
  transition: 0.3s;
  border: 1px solid #000;
  font-family: "Raleway", sans-serif;
  font-size: 1em;

  ${(props) =>
    props.selected &&
    css`
      background-color: rgb(255, 255, 255);
      text-transform: uppercase;
      letter-spacing: 0.1em;
      font-weight: 700;
      color: var(--button-color);
    `}

  // breakpoint(medium)
  @media (min-width: 50em) {
    padding: 14px 36px;
  }

  &:first-child {
    border-right: none;
  }

  &:hover {
    background-color: #ddd;
  }

  &.active {
    background-color: #fff;
  }
`;

const Tab = styled.div`
  display: block;
  margin-top: 20px;

  // breakpoint(medium)
  @media (min-width: 50em) {
    margin-top: 30px;
  }
`;

const Styled = {
  Tabs,
  TabSelector,
  Tab,
};

export default Styled;
