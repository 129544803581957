import React, { ReactNode } from "react";
import MainContent from "../../MainContent";
import Styled from "./styled";

interface ThreeColumnLayoutProps {
  left: ReactNode;
  mid: ReactNode;
  right: ReactNode;
}

const ThreeColumnLayout: React.FC<ThreeColumnLayoutProps> = ({
  left,
  mid,
  right,
}) => {
  return (
    <MainContent>
      <Styled.ThreeColumnLayout>
        <Styled.ThreeColumnLayoutLeft>{left}</Styled.ThreeColumnLayoutLeft>
        <Styled.ThreeColumnLayoutMid>{mid}</Styled.ThreeColumnLayoutMid>
        <Styled.ThreeColumnLayoutRight>{right}</Styled.ThreeColumnLayoutRight>
      </Styled.ThreeColumnLayout>
    </MainContent>
  );
};

export default ThreeColumnLayout;
