import React from "react";
import { observer } from "mobx-react";
import { useCompanyListStore } from "./CompanyListStore";
import CompanyList from "./CompanyList";
import ThreeColumnLayout from "../../common/layout/ThreeColumnLayout";
import TopEmployersWidget from "../../widgets/TopEmployersWidget";
import AdvertisementBannerWidget from "../../widgets/AdvertisementBannerWidget";
import JobsAggregationsWidget from "../../widgets/JobsAggregationsWidget";

const CompanyListPage = observer(function CompanyListPage() {
  const store = useCompanyListStore();

  const {
    randomCompanies,
    searchCompanies,
    searchCompaniesTotal,
    selectedLetter,
    isCreationAvailable,
    isCreationDialogShown,
    canLoadCompanies,
    setSelectedLetter,
    showCreationDialog,
    hideCreationDialog,
    loadMoreCompanies,
  } = store;

  return (
    <ThreeColumnLayout
      left={<JobsAggregationsWidget types={["classification"]} />}
      mid={
        <CompanyList
          randomCompanies={randomCompanies}
          searchCompanies={searchCompanies}
          searchCompaniesTotal={searchCompaniesTotal}
          selectedLetter={selectedLetter}
          canLoadCompanies={canLoadCompanies}
          updateSelectedLetter={setSelectedLetter}
          isCreationAvailable={isCreationAvailable}
          isCreationDialogShown={isCreationDialogShown}
          showCreationDialog={showCreationDialog}
          hideCreationDialog={hideCreationDialog}
          loadCompanies={loadMoreCompanies}
        />
      }
      right={
        <>
          <TopEmployersWidget />
          <AdvertisementBannerWidget />
        </>
      }
    />
  );
});

export default CompanyListPage;
