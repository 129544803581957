import styled from "styled-components";

const JobPremiumList = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  // breakpoint(xxlarge)
  @media (min-width: 95.375em) {
    grid-template-columns: repeat(2, 1fr);
  }

  box-sizing: border-box;
  column-gap: 20px;
  row-gap: 20px;

  .singlebox {
    background-color: white;
    padding: 20px 10px;
    box-sizing: border-box;

    .imgtext {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;

      picture {
        padding: 0;
        margin: 0;
      }

      article {
        flex: 1;
        padding-left: 20px;

        h2 {
          font-size: 1.1em;
          padding: 5px 0;
          margin: 0;

          a,
          a:visited {
            text-decoration: none;
          }

          a:hover {
            text-decoration: underline;
          }
        }

        p {
          font-size: 0.9em;
          line-height: 1.6;
          padding: 0;
          margin: 0;
          color: var(--font-grey);
        }
      }
    }
  }
`;

const Styled = {
  JobPremiumList,
};

export default Styled;
