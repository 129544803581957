import styled from "styled-components";

const AdvertisementBannerWidget = styled.div`
  margin-top: 20px;
  padding: 0 !important;
  background-color: white;
`;

const TopEmployersWidget = styled.div`
  padding: 20px;
  background-color: white;
  
  h2 {
    font-size: 1.1em;
    padding: 0;
    margin: 0 0 7px 0;
  }

  picture {
    text-align: center;
    width: 100%;
    vertical-align: middle;
    border-top: 1px solid $linegrey;
    display: inline-block;
    padding: 12px 35px;

    .helper {
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }

    img {
      width: 190px;
      vertical-align: middle;
    }
  }

  span {
    text-align: center;
    width: 100%;
    margin-bottom: 5px;
    display: inline-block;
  }

  @media (max-width: 1024px) {
    & {
      margin-top: 20px;
    }
  }
`;

const Styled = {
  AdvertisementBannerWidget,
  TopEmployersWidget,
};

export default Styled;
