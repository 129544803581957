import React from "react";
import { JobPremiumStoreProvider } from "./JobPremiumStore";
import JobPremiumPage from "./JobPremiumPage";

const JobPremiumRoot: React.FC = () => {
  return (
    <JobPremiumStoreProvider>
      <JobPremiumPage />
    </JobPremiumStoreProvider>
  );
};

export default JobPremiumRoot;
