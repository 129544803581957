import styled from "styled-components";

const JobMailerSubscriptionForm = styled.div`
  padding: 10px 30px;
  background-color: white;
`;

const Styled = {
  JobMailerSubscriptionForm,
};

export default Styled;
