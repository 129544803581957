import React, { useCallback } from "react";
import Styled from "./styled";

interface AlphabeticalSearchProps {
  selectedLetter: string | undefined;

  select(letter: string | undefined): void;
}

const AlphabeticalSearch: React.FC<AlphabeticalSearchProps> = ({
  selectedLetter,
  select,
}) => {
  const letters = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  return (
    <Styled.Alphabet>
      {letters.map((letter) => (
        <Letter
          key={letter}
          letter={letter}
          selected={letter === selectedLetter}
          select={select}
        />
      ))}
    </Styled.Alphabet>
  );
};

interface LetterProps {
  letter: string;
  selected: boolean;

  select(letter: string | undefined): void;
}

const Letter: React.FC<LetterProps> = ({ letter, selected, select }) => {
  const handleSelect = useCallback(() => {
    if (selected) {
      select(undefined);
    } else {
      select(letter);
    }
  }, [letter, selected, select]);

  return (
    <Styled.Letter selected={selected} onClick={handleSelect}>
      {letter}
    </Styled.Letter>
  );
};

export default AlphabeticalSearch;
