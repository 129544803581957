import React from "react";
import { JobSimilarStoreProvider } from "./JobSimilarStore";
import JobSimilarPage from "./JobSimilarPage";

interface JobSimilarRootProps {
  jobId: number;
}

const JobSimilarRoot: React.FC<JobSimilarRootProps> = ({ jobId }) => {
  return (
    <JobSimilarStoreProvider jobId={jobId}>
      <JobSimilarPage />
    </JobSimilarStoreProvider>
  );
};

export default JobSimilarRoot;
