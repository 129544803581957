import React from "react";
import { createContext, PropsWithChildren } from "react";

export interface Config {
  instance: string;
  dataServiceUrl: string;
  siteKey: string;
  advertisementGPT: string;
  email: string;
}

export const ConfigContext = createContext<Config | null>(null);

interface UserProviderProps extends PropsWithChildren<{}> {
  config: Config;
}

const ConfigProvider: React.FC<UserProviderProps> = ({ config, children }) => {
  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};

export default ConfigProvider;
