import React, { PropsWithChildren, useCallback, useMemo } from "react";
import Styled from "./styled";

interface TabsProps extends PropsWithChildren<{}> {
  selectedTabIndex: number;

  onTabSelect(name: string): void;
}

const Tabs: React.FC<TabsProps> = ({
  selectedTabIndex,
  onTabSelect,
  children,
}) => {
  const names: string[] = useMemo(
    () =>
      React.Children.map(children, (child: React.ReactNode) => {
        // @ts-ignore
        return child.props["name"];
      }) || [],
    [children]
  );

  const handleTabSelect = useCallback(
    (index: number) => {
      onTabSelect(names[index]);
    },
    [names, onTabSelect]
  );

  return (
    <>
      <Styled.Tabs>
        {names.map((name, index) => (
          <TabSelector
            index={index}
            key={name}
            name={name}
            selected={index === selectedTabIndex}
            onClick={handleTabSelect}
          />
        ))}
      </Styled.Tabs>
      {React.Children.map(children, (child: React.ReactNode, index: number) => {
        return <>{index === selectedTabIndex && <>{child}</>}</>;
      })}
    </>
  );
};

interface TabSelectorProps {
  name: string;
  index: number;
  selected: boolean;

  onClick(index: number): void;
}

const TabSelector: React.FC<TabSelectorProps> = ({
  name,
  index,
  selected,
  onClick,
}) => {
  const handleClick = useCallback(() => {
    onClick(index);
  }, [index, onClick]);

  return (
    <Styled.TabSelector key={index} selected={selected} onClick={handleClick}>
      {name}
    </Styled.TabSelector>
  );
};

export default Tabs;
