import React from "react";
import CompanyProfileSearchList from "./CompanyProfileSearchList";
import Button from "../../common/Button";
import CompanyCreationModal from "./CompanyCreationModal";
import Wrapper from "../../common/Wrapper";
import CompanyProfileList from "./CompanyProfileList";

interface CompanyListProps {
  randomCompanies: any[];
  searchCompanies: any[];
  searchCompaniesTotal: number;
  selectedLetter: string | undefined;
  isCreationAvailable: boolean;
  isCreationDialogShown: boolean;
  canLoadCompanies: boolean;

  updateSelectedLetter(letter: string | undefined): void;

  showCreationDialog(): void;

  hideCreationDialog(): void;

  loadCompanies(): void;
}

const CompanyList: React.FC<CompanyListProps> = ({
  randomCompanies,
  searchCompanies,
  searchCompaniesTotal,
  selectedLetter,
  updateSelectedLetter,
  isCreationAvailable,
  isCreationDialogShown,
  canLoadCompanies,
  showCreationDialog,
  hideCreationDialog,
  loadCompanies,
}) => {
  return (
    <>
      {isCreationAvailable && (
        <Wrapper>
          <Button
            label="Firma hinzufügen"
            primary
            onClick={showCreationDialog}
          />
        </Wrapper>
      )}
      <Wrapper>
        <h1>Arbeitgeber</h1>
      </Wrapper>
      <div>
        <CompanyProfileList companies={randomCompanies} />
        <CompanyProfileSearchList
          companies={searchCompanies}
          totalCount={searchCompaniesTotal}
          selectedLetter={selectedLetter}
          canLoadCompanies={canLoadCompanies}
          updateSelectedLetter={updateSelectedLetter}
          loadCompanies={loadCompanies}
        />
      </div>
      {isCreationDialogShown && (
        <CompanyCreationModal close={hideCreationDialog} />
      )}
    </>
  );
};
export default CompanyList;
