import styled from "styled-components";

const Modal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: black;
`;

const ModalInner = styled.div<{ wide?: boolean }>`
  padding: 15px;
  width: ${(props) => (props.wide ? "unset" : "370px")};
  border-radius: 1px;
  background-color: white;
`;

const ModalHeader = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid lightgray;
  margin-bottom: 10px;
  text-align: center;
`;

const ModalTitle = styled.h2`
  margin: 0;
  font-size: 17px;
  font-weight: 600;
  line-height: 14px;
`;

const ModalBody = styled.div``;

const ModalFooter = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: flex-end;

  &:first-child {
    margin-right: 10px;
  }
`;

const Styled = {
  Modal,
  ModalInner,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
};

export default Styled;
