import styled from "styled-components";

const Select = styled.select`
  width: 100%;
  font-size: 0.9em;
  font-family: "Raleway", sans-serif;
  text-indent: 10px;
  height: 45px;
  background-color: white;
  border: 1px solid black;
  -webkit-appearance: menulist-button;
`;

const Styled = {
  Select,
};

export default Styled;
