import React from "react";
import { action, observable, makeObservable, computed } from "mobx";
import axios from "axios";

export interface DocumentItem {
  file?: File;
  url?: string;
}

const defaultDocumentItem: DocumentItem = { file: undefined, url: undefined };

export class JobSubmissionFormStore {
  @observable disableSubmit: boolean = false;
  @observable documents: DocumentItem[] = [
    defaultDocumentItem,
    defaultDocumentItem,
    defaultDocumentItem,
  ];
  @observable products: string[];
  @observable selectedProduct?: string;
  @observable email?: string;
  @observable comment?: string;
  @observable showConfirmation: boolean = false;
  @observable error?: string;

  constructor(jobsProductPrices: number[]) {
    makeObservable(this);
    this.products = [
      `Einzelinserat (CHF ${jobsProductPrices[0]}.-)`,
      `Premium Inserat (CHF ${jobsProductPrices[1]}.-)`,
      `3er Abo Einzelinserat (CHF ${jobsProductPrices[2]}.-)`,
      "anderes Produkt (bitte im Kommentar beschreiben)",
    ];
    this.selectedProduct = this.products[0];
  }

  @computed
  get isFormEmpty() {
    return (
      !this.email &&
      !this.comment &&
      this.documents.every((doc: DocumentItem) => !doc.file && !doc.url)
    );
  }

  @action
  readonly updateDocument = (index: number, file?: File, url?: string) => {
    const updatedDocuments = [...this.documents];
    updatedDocuments[index].file = file;
    updatedDocuments[index].url = url;
    this.documents = updatedDocuments;
  };

  @action
  readonly selectProduct = (product: string) => {
    this.selectedProduct = product;
  };

  @action
  readonly setEmail = (email: string) => {
    this.email = email;
  };

  @action
  readonly setComment = (comment: string) => {
    this.comment = comment;
  };

  @action
  readonly validate = () => {
    this.error = undefined;

    let errorMessage = "";

    if (
      !this.documents.some((document: DocumentItem) => {
        return !!document.url || !!document.file;
      })
    ) {
      errorMessage += "Mindestens ein Dokument oder eine URL ist erforderlich.";
    }

    for (let i = 0; i < this.documents.length; i++) {
      const document = this.documents[i];

      if (!document.url) {
        continue;
      }

      const isValidUrl =
        /https:\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/.test(
          document.url
        );

      if (!isValidUrl) {
        errorMessage += `${errorMessage.length > 0 ? "\n" : ""}URL '${
          document.url
        }' ist ungültig.`;
      }
    }

    if (!this.email) {
      errorMessage += `${
        errorMessage.length > 0 ? "\n" : ""
      }E-Mail ist erforderlich.`;
    } else if (
      !/^[\w-.]+@([\w-]+\.)+[\w-]+$/.test(this.email!)
    ) {
      errorMessage += `${errorMessage.length > 0 ? "\n" : ""}Ungültige E-Mail.`;
    }

    if (this.selectedProduct?.startsWith("other") && !this.comment) {
      errorMessage += `${
        errorMessage.length > 0 ? "\n" : ""
      }Für das ausgewählte Produkt ist ein Kommentar erforderlich.`;
    }

    if (errorMessage.length > 0) {
      this.error = errorMessage;
      return false;
    } else {
      return true;
    }
  };

  @action
  readonly processFormData = (captchaToken?: string) => {
    if (!this.validate()) {
      return;
    }
    const data = new FormData();
    data.append("captchaToken", captchaToken!);
    data.append("product", this.selectedProduct!);
    data.append("email", this.email!);
    if (!!this.comment) {
      data.append("comment", this.comment!);
    }

    const files = this.documents
      .filter((doc) => !!doc.file)
      .map((doc) => doc.file!);
    const urls = this.documents
      .filter((doc) => !!doc.url)
      .map((doc) => doc.url!);

    if (urls && urls.length > 0) {
      urls.forEach((url) => {
        data.append("urls[]", url);
      });
    }
    if (files && files.length > 0) {
      files.forEach((file) => {
        data.append("files[]", file, file.name);
      });
    }

    axios
      .post("/jobs-api/send-submission-email/", data)
      .then(() => {
        this.error = undefined;
        this.showConfirmation = true;
      })
      .catch((error) => {
        this.error = error.response.data.error;
      })
      .finally(() => (this.disableSubmit = false));
  };
}

const storeContext = React.createContext<JobSubmissionFormStore | null>(null);

export const JobSubmissionFormStoreProvider = ({
  jobsProductPrices,
  children,
}: React.PropsWithChildren<{ jobsProductPrices: number[] }>) => {
  const store = new JobSubmissionFormStore(jobsProductPrices);
  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
};

export const useJobSubmissionFormStore = () => {
  const store = React.useContext(storeContext);
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error(
      "useJobSubmissionFormStore must be used within a JobSubmissionFormStoreProvider."
    );
  }
  return store;
};
