import React from "react";
import JobAllList from "./JobAllList";
import Wrapper from "../../common/Wrapper";
import PaginationLoadMore from "../../common/PaginationLoadMore";
import { Job } from "../types";

interface JobListProps {
  title: string;
  jobs?: Job[];
  totalCount?: number;
  canLoadMore: boolean;

  loadJobs(): void;
}

const JobList: React.FC<JobListProps> = ({
  title,
  jobs,
  totalCount,
  canLoadMore,
  loadJobs,
}) => {
  return (
    <>
      <Wrapper>
        <h1>
          {title}
          {totalCount !== undefined && <span>({totalCount} Treffer)</span>}
        </h1>
      </Wrapper>
      {!!jobs && <JobAllList jobs={jobs} />}
      {!jobs && <div className="center">Loading...</div>}
      {!!jobs && !!totalCount && totalCount - jobs.length > 0 && (
        <PaginationLoadMore loadDisabled={!canLoadMore} load={loadJobs} />
      )}
    </>
  );
};

export default JobList;
