import styled from "styled-components";

const JobDetailsContacts = styled.div`
  padding: 0;
  margin: 0;

  .allbut {
    display: block;
    padding: 0;

    ul {
      max-width: 900px;
      margin: 20px auto 0;
      padding: 0;
      box-sizing: border-box;
      display: flex;

      li {
        text-align: center;
        box-sizing: border-box;
        list-style: none;
        display: block;
        margin-right: 10px;

        a,
        a:visited {
          cursor: pointer;
          box-sizing: border-box;
          display: block;
          font-size: 0.8em;
          height: 67px;

          // breakpoint(large)
          @media (min-width: 68.375em) {
            font-size: 1em;
            letter-spacing: 0.1em;
          }

          font-weight: 700;
          padding: 24px 10px;
          text-decoration: none;
          width: 100%;
          color: white;
          text-transform: uppercase;
          background-color: var(--button-color);
          transition: background-color 0.3s ease;
        }

        a:hover {
          background-color: var(--button-color-hover);
        }
      }

      li:last-child {
        margin-right: 0;
      }

      .left {
        flex: 1;
        box-sizing: border-box;
      }

      .mid {
        flex: 1;
        padding: inherit;

        a,
        a:visited {
          background-color: white;
          color: var(--button-color);
          height: 67px;
          box-sizing: border-box;
          padding-top: 18px;

          // breakpoint(xxsmall)
          @media (min-width: 25.3em) {
            padding-top: 24px;
          }
        }

        a:hover {
          background-color: white;
        }
      }

      .mid2 {
        flex: 1;
        display: none;

        // breakpoint(small)
        @media (min-width: 44.5em) {
          display: block;
        }

        a,
        a:visited {
          background-color: white;
          color: var(--button-color);
          height: 67px;
          box-sizing: border-box;
        }

        a:hover {
          background-color: white;
        }
      }

      .right {
        width: 67px;
        box-sizing: border-box;

        a,
        a:visited {
          box-sizing: border-box;
          background-color: white;
          height: 67px;
          width: 67px;
          border-radius: 50px;
          padding: 17px 0 0 0;

          .icon {
            fill: var(--button-color-hover);
            width: 36px;
            height: 36px;
          }
        }

        a:hover {
          background-color: white;
        }
      }
    }
  }

  bottom: 0;
  width: 100%;
  z-index: 100;

  & .single ul {
    max-width: 600px;
  }
`;

const JobDetailsDescription = styled.div`
  box-sizing: border-box;
  background-color: white;
  padding: 10px 8px;

  // breakpoint(xsmall)
  @media (min-width: 34.5em) {
    padding: 10px 25px 20px 25px;
  }

  .headdet {
    .icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin: 0 4px -5px 0;
    }

    span {
      margin: 0 10px;
    }

    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    .dleft {
      flex: 1;
    }

    .dright {
      display: none;

      // breakpoint(xsmall)
      @media (min-width: 34.5em) {
        display: block;
        width: 190px;
        text-align: center;
        justify-content: center;

        .image {
          justify-content: center;
          align-items: center;
          display: block;
        }
      }
    }
  }

  .iframe {
    border: 4px solid var(--background-grey);
    min-height: 800px;
    margin-top: 10px;
    width: 100%;
  }
`;

const JobDetailsNavigationLink = styled.a`
  display: block;
  padding: 9px 14px 9px 45px;
  background-color: var(--background-grey);
  position: relative;
  border-radius: 5px;
  box-sizing: border-box;
  text-decoration: none;
  transition: background-color 0.3s ease;
  color: black;

  svg {
    position: absolute;
    left: 12px;
    top: 3px;

    // breakpoint(large)
    @media (min-width: 68.375em) {
      top: 7px;
    }
  }

  // breakpoint(large)
  @media (min-width: 68.375em) {
    padding: 9px 14px 9px 45px;
  }

  &:hover {
    background-color: var(--search-background-grey);
  }
`;

const Styled = {
  JobDetailsContacts,
  JobDetailsDescription,
  JobDetailsNavigationLink,
};

export default Styled;
