import React, { useCallback, useEffect, useState } from "react";
import SearchImage from "./SearchImage";
import SearchNoImage from "./SearchNoImage";
import Styled from "./styled";

interface SearchProps {
  defaultQuery?: string;
  showImage?: boolean;

  onSearch(query?: string): void;
}

const Search: React.FC<SearchProps> = ({
  defaultQuery,
  showImage,
  onSearch,
}) => {
  const [query, setQuery] = useState<string>();

  const handleQueryChange = useCallback((e: any) => {
    setQuery(e.target.value || "");
  }, []);

  const handleSubmit = useCallback(() => {
    onSearch(query);
    setQuery(undefined);
  }, [query, onSearch]);

  const handleEnter = useCallback(
    (e: any) => {
      // on enter press
      if (e.keyCode === 13) {
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  useEffect(() => {
    setQuery(defaultQuery);
  }, [defaultQuery]);

  return (
    <Styled.Search>
      {showImage && <SearchImage />}
      {!showImage && <SearchNoImage />}
      <Styled.SearchField className={showImage ? "" : "no-image"}>
        <Styled.SearchWrapper>
          <Styled.SearchBox>
            <Styled.SearchForm>
              <Styled.SearchFormLeft>
                <Styled.SearchInputField>
                  <Styled.SearchInput
                    type="text"
                    id="search"
                    tabIndex={0}
                    name="search"
                    placeholder=" "
                    value={query}
                    onChange={handleQueryChange}
                    onKeyUp={handleEnter}
                  />
                  <Styled.SearchLabel htmlFor="search">
                    Beruf, Ort oder Unternehmen
                  </Styled.SearchLabel>
                </Styled.SearchInputField>
              </Styled.SearchFormLeft>
              <Styled.SearchFormRight>
                <Styled.SearchButton type="submit" onClick={handleSubmit}>
                  Search
                </Styled.SearchButton>
              </Styled.SearchFormRight>
            </Styled.SearchForm>
          </Styled.SearchBox>
        </Styled.SearchWrapper>
      </Styled.SearchField>
    </Styled.Search>
  );
};

export default Search;
