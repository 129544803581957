import React, { LegacyRef, useCallback, useContext } from "react";
import JobSubmissionFormOrder from "./JobSubmissionFormOrder";
import JobSubmissionFormJobs from "./JobSubmissionFormJobs";
import JobSubmissionFormContacts from "./JobSubmissionFormContacts";
import { DocumentItem } from "./JobSubmissionFormStore";
import { ConfigContext } from "../../../providers/ConfigProvider";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "../../common/Button";

interface JobSubscriptionFormProps {
  documents: DocumentItem[];
  products: string[];
  selectedProduct?: string;
  email?: string;
  comment?: string;
  submitDisabled: boolean;
  isFormEmpty: boolean;
  error?: string;

  selectProduct(product: string): void;

  updateEmail(email: string): void;

  updateComment(comment: string): void;

  updateDocument(index: number, file?: File, url?: string): void;

  submitForm(token?: string): void;
}

const JobSubscriptionForm: React.FC<JobSubscriptionFormProps> = ({
  documents,
  products,
  selectedProduct,
  email,
  comment,
  submitDisabled,
  isFormEmpty,
  error,
  selectProduct,
  updateEmail,
  updateComment,
  updateDocument,
  submitForm,
}) => {
  const recaptchaRef: LegacyRef<any> = React.useRef();
  const siteKey = useContext(ConfigContext)?.siteKey!;

  const handleFormSubmit = useCallback(async () => {
    if (submitDisabled) return;
    if (isFormEmpty) {
      submitForm();
    } else {
      const token = await recaptchaRef.current.executeAsync();
      submitForm(token);
      recaptchaRef.current.reset();
    }
  }, [isFormEmpty, submitDisabled, submitForm]);

  return (
    <>
      <form>
        {!!error && <div className="alert alert-danger">{error}</div>}
        <div className="right">
          <JobSubmissionFormOrder
            products={products}
            selectedProduct={selectedProduct}
            selectProduct={selectProduct}
          />
        </div>
        <div className="left">
          <JobSubmissionFormJobs
            documents={documents}
            updateDocument={updateDocument}
          />
          <hr />
          <JobSubmissionFormContacts
            email={email}
            comment={comment}
            updateEmail={updateEmail}
            updateComment={updateComment}
          />
          <Button
            label="Absenden"
            disabled={submitDisabled}
            onClick={handleFormSubmit}
            primary
          />
        </div>
      </form>
      <ReCAPTCHA ref={recaptchaRef} sitekey={siteKey} size="invisible" />
    </>
  );
};
export default JobSubscriptionForm;
