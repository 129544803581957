import React, { useCallback } from "react";
import Styled from "./styled";

interface SelectProps {
  options: string[];
  selectedOption?: string;

  onSelect(option: string): void;
}

const Select: React.FC<SelectProps> = ({
  options,
  selectedOption,
  onSelect,
}) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      onSelect(e.target.value);
    },
    [onSelect]
  );

  return (
    <Styled.Select value={selectedOption} onChange={handleChange}>
      {(options || []).map((option, index) => (
        <option key={index}>{option}</option>
      ))}
    </Styled.Select>
  );
};

export default Select;
