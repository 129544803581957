import styled from "styled-components";

const JobListItem = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 20px 0px;

  // breakpoint(large) {
  @media (min-width: 68.375em) {
    padding: 20px 20px;
  }

  border-bottom: 1px solid var(--line-grey);
  align-items: center;
  justify-content: center;

  picture {
    padding: 0;
    margin: 0;
  }

  &:last-child {
    border-bottom: none;
  }
`;

const JobListItemImage = styled.div`
  padding-right: 20px;
`;

const JobListItemInfo = styled.div`
  flex: 1;
`;

const JobListItemInfoName = styled.div`
  font-size: 1.1em;
  font-weight: 600;
  padding: 0px 0 5px 0;
  margin: 0;

  a,
  a:visited {
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

const JobListItemInfoCompany = styled.div`
  font-size: 0.9em;
  line-height: 1.6;
  padding: 0;
  margin: 0;
  color: var(--font-grey);

  span {
    display: inline;

    // breakpoint(xsmall)
    @media (min-width: 34.5em) {
      display: none;
    }
  }
`;

const JobListItemLocation = styled.div`
  display: none;

  // breakpoint(xsmall)
  @media (min-width: 34.5em) {
    display: block;
  }

  width: 140px;
  text-align: right;
  font-size: 0.9em;
`;

const Styled = {
  JobListItem,
  JobListItemImage,
  JobListItemInfo,
  JobListItemInfoName,
  JobListItemInfoCompany,
  JobListItemLocation,
};

export default Styled;
