import React from "react";
import DOMPurify from "isomorphic-dompurify";
import JobDetailsDescriptionHeader from "./JobDetailsDescriptionHeader";
import IframeResizer from "iframe-resizer-react";
import { JobDetails } from "../types";
import Styled from "./styled";

interface JobDetailsDescriptionProps {
  job: JobDetails;
  companyProfileUrl: string;
}

const JobDetailsDescription: React.FC<JobDetailsDescriptionProps> = ({
  job,
  companyProfileUrl,
}) => {
  const language = job.language;
  const companyDescriptionSafe = !!job.company?.description
    ? DOMPurify.sanitize(job.company.description)
    : undefined;
  const descriptionSafe =
    !!job.description && DOMPurify.sanitize(job.description);
  const requirementsSafe =
    !!job.requirements && DOMPurify.sanitize(job.requirements);
  const offerSafe = !!job.offer && DOMPurify.sanitize(job.offer);
  const contactAddress =
    job.contact &&
    job.contact.address &&
    DOMPurify.sanitize(job.contact.address.replace(/\n/g, "<br/>"));
  const refCode =
    job.refCode && DOMPurify.sanitize(job.refCode.replace(/\n/g, "<br/>"));

  return (
    <Styled.JobDetailsDescription className="textcontent">
      <JobDetailsDescriptionHeader
        job={job}
        companyProfileUrl={companyProfileUrl}
      />
      <section>
        {!job.displayAsText && (
          <IframeResizer
            src={job.fileUrl}
            log={false}
            scrolling={true}
            heightCalculationMethod="lowestElement"
            className="iframe"
          />
        )}
        {job.displayAsText && (
          <>
            {companyDescriptionSafe && (
              <div
                dangerouslySetInnerHTML={{ __html: companyDescriptionSafe }}
              />
            )}
            {descriptionSafe && (
              <>
                <h3>
                  {language === "DE"
                    ? "Stellenbeschreibung"
                    : "Job description"}
                </h3>
                <div dangerouslySetInnerHTML={{ __html: descriptionSafe }} />
              </>
            )}
            {requirementsSafe && (
              <>
                <h3>{language === "DE" ? "Anforderungen" : "Requirements"}</h3>
                <div dangerouslySetInnerHTML={{ __html: requirementsSafe }} />
              </>
            )}
            {offerSafe && (
              <>
                <h3>{language === "DE" ? "Angebot" : "Offer"}</h3>
                <div dangerouslySetInnerHTML={{ __html: offerSafe }} />
              </>
            )}
            {(contactAddress || refCode) && <br />}
            {contactAddress && (
              <div dangerouslySetInnerHTML={{ __html: contactAddress }} />
            )}
            {refCode && (
              <div>
                Referenz-Nummer:
                <span dangerouslySetInnerHTML={{ __html: refCode }} />
              </div>
            )}
          </>
        )}
      </section>
    </Styled.JobDetailsDescription>
  );
};

export default JobDetailsDescription;
