import React from "react";
import axios from "axios";
import { action, observable, makeObservable } from "mobx";
import { getJsonItem, setJsonItem } from "../../../utils/localstorage-utils";
import { goToUrl, insertSingleParam } from "../../../utils/url-utils";
import { Job } from "../types";

export class JobPremiumStore {
  @observable allJobsCount?: number;
  @observable premiumJobs?: Job[];
  @observable lastSearches: string[] = getJsonItem("itj-last-searches") || [];

  constructor() {
    makeObservable(this);
    this.init();
  }

  readonly init = () => {
    this.loadPremiumJobs();
  };

  @action
  readonly setQuery = (query: string) => {
    if (query && query.length > 0) {
      let searches = [...this.lastSearches, query].filter(
        (search, index, self) => self.indexOf(search) === index
      );
      if (searches.length > 10) {
        searches = searches.slice(1);
      }
      this.lastSearches = searches;
      setJsonItem("itj-last-searches", searches);
    }
    goToUrl(`/jobs/?${insertSingleParam("q", query || "")}`);
  };

  @action readonly removeLastSearch = (query: string) => {
    const searches = this.lastSearches.filter((search) => search !== query);
    this.lastSearches = searches;
    setJsonItem("itj-last-searches", searches);
  };

  @action readonly clearLastSearches = () => {
    this.lastSearches = [];
    setJsonItem("itj-last-searches", []);
  };

  @action readonly loadPremiumJobs = () => {
    axios.get("/jobs-api/premium/list/").then(
      action((response) => {
        this.premiumJobs = response.data.jobs;
        this.allJobsCount = response.data.allJobsCount;
      })
    );
  };
}

const storeContext = React.createContext<JobPremiumStore | null>(null);

export const JobPremiumStoreProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const store = new JobPremiumStore();
  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
};

export const useJobPremiumStore = () => {
  const store = React.useContext(storeContext);
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error(
      "useJobPremiumStore must be used within a JobPremiumStoreProvider."
    );
  }
  return store;
};
