import React from "react";
import { JobSubmissionFormStoreProvider } from "./JobSubmissionFormStore";
import JobSubmissionFormPage from "./JobSubmissionFormPage";

interface JobSubmissionFormRootProps {
  jobsProductPrices: number[];
}

const JobSubmissionFormRoot: React.FC<JobSubmissionFormRootProps> = ({
  jobsProductPrices,
}) => {
  return (
    <JobSubmissionFormStoreProvider jobsProductPrices={jobsProductPrices}>
      <JobSubmissionFormPage />
    </JobSubmissionFormStoreProvider>
  );
};

export default JobSubmissionFormRoot;
