import React, { useCallback } from "react";
import HorizontalScroll from "../../common/HorizontalScroll";
import { goBack } from "../../../utils/url-utils";
import Link from "../../common/Link";
import SvgIcon from "../../common/SvgIcon";
import Styled from "./styled";

interface JobDetailsNavigationProps {
  jobId: number;
  companyUrl: string;
  jobMailerUrl: string;
  companyProfileUrl: string;
  similarJobsUrl: string;
}

const JobDetailsNavigation: React.FC<JobDetailsNavigationProps> = ({
  jobId,
  companyUrl,
  jobMailerUrl,
  companyProfileUrl,
  similarJobsUrl,
}) => {
  const handleGoBack = useCallback(() => {
    goBack("/jobs");
  }, []);

  return (
    <HorizontalScroll>
      <Link onClick={handleGoBack}>
        <SvgIcon name="arrowback" />
      </Link>
      <Styled.JobDetailsNavigationLink
        href={`/${jobMailerUrl}/${jobId}`}
        id="Jobmail_Registration"
        rel="nofollow"
      >
        <SvgIcon name="mail" />
        Job Mail erstellen
      </Styled.JobDetailsNavigationLink>
      {companyProfileUrl && <Link href={companyProfileUrl}>Firma ansehen</Link>}
      <Link href={`/jobs/?company=${companyUrl}`}>Alle Jobs dieser Firma</Link>
      <Link href={similarJobsUrl} rel="nofollow">
        Ähnliche Jobs
      </Link>
    </HorizontalScroll>
  );
};

export default JobDetailsNavigation;
