import React, { PropsWithChildren } from "react";
import Styled from "./styled";

interface TabProps extends PropsWithChildren<{}> {
  name: string;
}

const Tab: React.FC<TabProps> = ({ name, children }) => {
  return <Styled.Tab>{children}</Styled.Tab>;
};

export default Tab;
