import React, { useEffect, useState } from "react";
import axios from "axios";
import Link from "../common/Link";
import Styled from "./styled";

const TopEmployersWidget: React.FC = () => {
  const [employers, setEmployers] = useState<any[]>([]);

  useEffect(() => {
    axios
      .get("/employer-teaser-image/random/")
      .then((response) => setEmployers(response.data));
  }, []);

  return (
    <Styled.TopEmployersWidget>
      <h2>Top Arbeitgeber</h2>
      {employers.map((employer) => (
        <Link
          key={employer.id}
          href={employer.targetUrl}
          rel="noopener"
          target={employer.targetUrl.startsWith("http") ? "_blank" : "_self"}
        >
          <picture>
            <img src={employer.imageUrl} alt={employer.alt} />
          </picture>
          {!!employer.description && <span>{employer.description}</span>}
        </Link>
      ))}
    </Styled.TopEmployersWidget>
  );
};

export default TopEmployersWidget;
