import React, { PropsWithChildren } from "react";
import Styled from "./styled";

interface HorizontalScrollProps extends PropsWithChildren<{}> {
  lastOnRight?: boolean;
}

const HorizontalScroll: React.FC<HorizontalScrollProps> = ({
  lastOnRight,
  children,
}) => {
  return (
    <Styled.HorizontalScrollWrapper>
      <Styled.HorizontalScroll>
        <Styled.HorizontalScrollItems>
          {React.Children.toArray(children)
            .filter((child) => !!child)
            .map((child, index, array) => (
              <Styled.HorizontalScrollItem
                key={index}
                onRight={lastOnRight && index === array.length - 1}
              >
                {child}
              </Styled.HorizontalScrollItem>
            ))}
        </Styled.HorizontalScrollItems>
      </Styled.HorizontalScroll>
    </Styled.HorizontalScrollWrapper>
  );
};

export default HorizontalScroll;
