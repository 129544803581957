import React, { PropsWithChildren } from "react";
import MainContent from "../../MainContent";
import Styled from "./styled";

interface OneColumnLayouyProps extends PropsWithChildren<{}> {}

const OneColumnLayout: React.FC<OneColumnLayouyProps> = ({ children }) => {
  return (
    <MainContent>
      <Styled.OneColumnLayout>{children}</Styled.OneColumnLayout>
    </MainContent>
  );
};

export default OneColumnLayout;
