import styled from "styled-components";

const JobSubmissionFormInfo = styled.span`
  display: inline-block;
  border-radius: 150px;
  background-color: black;
  color: white;
  font-weight: 700;
  border: none;
  font-size: 0.8em;
  width: 22px;
  height: 22px;
  cursor: pointer;
  margin-left: 10px;
  text-align: center;
`;

const Styled = {
  JobSubmissionFormInfo,
};

export default Styled;
