import React from "react";
import { observer } from "mobx-react";
import { useJobSimilarStore } from "./JobSimilarStore";
import ThreeColumnLayout from "../../common/layout/ThreeColumnLayout";
import NestedList from "../../common/NestedList";
import JobSimilarList from "./JobSimilarList";
import JobSimilarNavigation from "../premium/JobSimilarNavigation";
import TopEmployersWidget from "../../widgets/TopEmployersWidget";
import AdvertisementBannerWidget from "../../widgets/AdvertisementBannerWidget";

const JobSimilarPage = observer(function JobSimilarPage() {
  const store = useJobSimilarStore();
  const { jobs, aggregationsList } = store;

  return (
    <>
      <JobSimilarNavigation />
      <ThreeColumnLayout
        left={<NestedList nodes={aggregationsList} />}
        mid={<JobSimilarList jobs={jobs} />}
        right={
          <>
            <TopEmployersWidget />
            <AdvertisementBannerWidget />
          </>
        }
      />
    </>
  );
});

export default JobSimilarPage;
