import React, { useCallback, useRef } from "react";
import Link from "../../common/Link";
import Input from "../../common/Input/Input";
import { DocumentItem } from "./JobSubmissionFormStore";

interface JobSubmissionFormJobsProps {
  documents: DocumentItem[];

  updateDocument(index: number, file?: File, url?: string): void;
}

const JobSubmissionFormJobs: React.FC<JobSubmissionFormJobsProps> = ({
  documents,
  updateDocument,
}) => {
  const handleFileUpload = useCallback(
    (index: number, file?: File) => {
      updateDocument(index, file, documents[index].url);
    },
    [documents, updateDocument]
  );

  const handleUrlChange = useCallback(
    (index: number, url?: string) => {
      updateDocument(index, documents[index].file, url);
    },
    [documents, updateDocument]
  );

  return (
    <div className="flexcon">
      <div className="one">
        <label>
          <strong>Datei</strong>
        </label>
        <JobFileInput
          index={0}
          value={documents[0]}
          uploadFile={handleFileUpload}
        />
        <JobFileInput
          index={1}
          value={documents[1]}
          uploadFile={handleFileUpload}
        />
        <JobFileInput
          index={2}
          value={documents[2]}
          uploadFile={handleFileUpload}
        />
      </div>
      <div className="two">oder</div>
      <div className="three">
        <label className="">
          <strong>URL</strong>
        </label>
        <JobUrlInput
          index={0}
          value={documents[0]}
          updateUrl={handleUrlChange}
        />
        <JobUrlInput
          index={1}
          value={documents[1]}
          updateUrl={handleUrlChange}
        />
        <JobUrlInput
          index={2}
          value={documents[2]}
          updateUrl={handleUrlChange}
        />
      </div>
    </div>
  );
};

interface JobFileInputProps {
  index: number;
  value: DocumentItem;

  uploadFile(index: number, file?: File): void;
}

const JobFileInput: React.FC<JobFileInputProps> = ({
  index,
  value,
  uploadFile,
}) => {
  const ref: React.LegacyRef<any> = useRef();
  const handleUploadClick = useCallback(() => {
    !value.url && ref.current.click();
  }, [value]);

  const handleFileUpload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        uploadFile(index, e.target.files[0]);
      }
    },
    [index, uploadFile]
  );

  const handleFileRemove = useCallback(() => {
    uploadFile(index, undefined);
  }, [index, uploadFile]);

  return (
    <>
      {value.file && (
        <div className="inputwrapper">
          <Input
            type="text"
            value={value.file?.name}
            className="tobospace"
            readonly
          />
          <svg className="icon delete" onClick={handleFileRemove}>
            <use xlinkHref="#delete" />
          </svg>
        </div>
      )}
      {!value.file && (
        <>
          <input
            ref={ref}
            type="file"
            onChange={handleFileUpload}
            accept=".doc,.docx,.pdf"
            hidden
            style={{ display: "none" }}
          />
          <Link
            className={`btn width100 greybtn tobospace ${
              !!value.url ? "disabled no-click" : ""
            }`}
            onClick={handleUploadClick}
          >
            Hinzufügen
          </Link>
        </>
      )}
    </>
  );
};

interface JobUrlInputProps {
  index: number;
  value: DocumentItem;

  updateUrl(index: number, url?: string): void;
}

const JobUrlInput: React.FC<JobUrlInputProps> = ({
  index,
  value,
  updateUrl,
}) => {
  const handleValueUpdate = useCallback(
    (value: string | undefined) => {
      updateUrl(index, value);
    },
    [index, updateUrl]
  );

  return (
    <Input
      type="text"
      placeholder="Weitere URL"
      className={`tobospace ${!!value.file ? "disabled no-click" : ""}`}
      value={value?.url}
      onValueUpdate={handleValueUpdate}
      readonly={!!value.file}
    />
  );
};

export default JobSubmissionFormJobs;
