import React, { useCallback } from "react";
import Link from "../Link";

interface ButtonProps {
  label: string;
  href?: string;
  primary?: boolean;
  disabled?: boolean;
  eventId?: string;

  onClick?(): void;
}

const Button: React.FC<ButtonProps> = ({
  label,
  href,
  primary,
  disabled,
  eventId,
  onClick,
}) => {
  const handleClick = useCallback(() => {
    !disabled && onClick && onClick();
  }, [disabled, onClick]);

  return (
    <div>
      <Link
        href={href}
        target="_blank"
        rel="noopener"
        className={`btn width100 ${!primary ? "greybtn fontreg" : ""} ${
          disabled ? "btn-disabled" : ""
        }`}
        onClick={handleClick}
        eventId={eventId}
      >
        {label}
      </Link>
    </div>
  );
};

export default Button;
