import React from "react";
import { CompanyListStoreProvider } from "./CompanyListStore";
import CompanyListPage from "./CompanyListPage";

interface CompanyListRootProps {
  companiesPerPage: number;
}

const CompanyListRoot: React.FC<CompanyListRootProps> = ({
  companiesPerPage,
}) => {
  return (
    <CompanyListStoreProvider companiesPerPage={companiesPerPage}>
      <CompanyListPage />
    </CompanyListStoreProvider>
  );
};

export default CompanyListRoot;
