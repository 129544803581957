import styled from "styled-components";

const SvgIcon = styled.svg`
  width: 24px;
  height: 24px;
`;

const Styled = {
  SvgIcon,
};

export default Styled;
