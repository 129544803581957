import React from "react";
import CompanyProfileList from "./CompanyProfileList";
import AlphabeticalSearch from "../../common/AlphabeticalSearch";
import PaginationLoadMore from "../../common/PaginationLoadMore";

interface CompanyProfileSearchListProps {
  companies: any[];
  totalCount: number;
  selectedLetter: string | undefined;
  canLoadCompanies: boolean;

  updateSelectedLetter(letter: string | undefined): void;

  loadCompanies(): void;
}

const CompanyProfileSearchList: React.FC<CompanyProfileSearchListProps> = ({
  companies,
  totalCount,
  selectedLetter,
  canLoadCompanies,
  updateSelectedLetter,
  loadCompanies,
}) => {
  return (
    <>
      {companies && (
        <>
          <AlphabeticalSearch
            selectedLetter={selectedLetter}
            select={updateSelectedLetter}
          />
          <CompanyProfileList companies={companies} />
          {totalCount - companies.length > 0 && (
            <PaginationLoadMore
              loadDisabled={!canLoadCompanies}
              load={loadCompanies}
            />
          )}
        </>
      )}
    </>
  );
};
export default CompanyProfileSearchList;
