import React, { useCallback } from "react";
import Wrapper from "../../common/Wrapper";
import InputBase from "../../common/Input/InputBase";
import Button from "../../common/Button";
import DropdownTreeSelect from "react-dropdown-tree-select";
import Link from "../../common/Link";
import Styled from "./styled";

interface JobMailerProfileFormProps {
  profile: any;
  classifications: any[];
  regions: any[];
  showExpiration: boolean;
  showSuccessMessage: boolean;
  error?: string;

  onSubmit(): void;

  onDeactivate(): void;

  onClassificationsSelect(classifications: string[]): void;

  onRegionsSelect(regions: string[]): void;
}

const JobMailerProfileForm: React.FC<JobMailerProfileFormProps> = ({
  profile,
  classifications,
  regions,
  showExpiration,
  showSuccessMessage,
  error,
  onSubmit,
  onDeactivate,
  onClassificationsSelect,
  onRegionsSelect,
}) => {
  const handleClassificationsChange = useCallback(
    (currentNode: any, selectedNodes: any) => {
      onClassificationsSelect(selectedNodes.map((node: any) => node.fullPath));
    },
    [onClassificationsSelect]
  );

  const handleRegionsChange = useCallback(
    (currentNode: any, selectedNodes: any) => {
      onRegionsSelect(selectedNodes.map((node: any) => node.fullPath));
    },
    [onRegionsSelect]
  );

  return (
    <>
      <Wrapper>
        <h1>Job Mailer</h1>
      </Wrapper>
      <Styled.JobMailerProfileForm>
        {showSuccessMessage && (
          <div className="alert alert-success">
            Das Profil wurde erfolgreich gespeichert
          </div>
        )}
        {error && <div className="alert alert-danger">{error}</div>}
        {showExpiration && (
          <div className="alert alert-danger">
            Es tut uns leid, aber diese Sitzung ist abgelaufen. Bitte fordern
            Sie einen neuen Link an, indem Sie&nbsp;
            <Link href={window.location.pathname.replace("profile/", "")}>
              diese Form
            </Link>
            &nbsp;ausfüllen
          </div>
        )}
        {!showExpiration && profile && (
          <>
            <Styled.JobMailerProfileFormHeader>
              <h4>Abonnementverwaltung </h4>
              <h4>{profile.email} </h4>
            </Styled.JobMailerProfileFormHeader>
            <Styled.JobMailerProfileFormContent>
              <InputBase label="Klassifikationen">
                <DropdownTreeSelect
                  data={classifications}
                  onChange={handleClassificationsChange}
                  keepTreeOnSearch={true}
                  showPartiallySelected={true}
                  clearSearchOnChange={true}
                />
              </InputBase>
              <InputBase label="Regionen">
                <DropdownTreeSelect
                  data={regions}
                  onChange={handleRegionsChange}
                  keepTreeOnSearch={true}
                  showPartiallySelected={true}
                  clearSearchOnChange={true}
                />
              </InputBase>
            </Styled.JobMailerProfileFormContent>
            <Styled.JobMailerProfileFormFooter>
              <Button
                label="Speichern & Abonnieren"
                onClick={onSubmit}
                primary
              />
            </Styled.JobMailerProfileFormFooter>
            {profile.active && (
              <Styled.JobMailerProfileFormFooterLink>
                <Link onClick={onDeactivate}>Vom Job Mailer abmelden</Link>
              </Styled.JobMailerProfileFormFooterLink>
            )}
          </>
        )}
      </Styled.JobMailerProfileForm>
    </>
  );
};

export default JobMailerProfileForm;
