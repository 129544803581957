import styled from "styled-components";
import WrapperStyled from "../../common/Wrapper/styled";

const Search = styled.div`
  position: relative;

  ${WrapperStyled.Wrapper} {
    padding: 0 0;

    // breakpoint(xlarge)
    @media (min-width: 80.375em) {
      padding: 0 25px;
    }
  }
`;

const SearchBackground = styled.div`
  height: 135px;
  background-color: #dfdfdf;

  // breakpoint(small)
  @media (min-width: 44.5em) {
    height: 116px;
  }
`;

const SearchBox = styled.div`
  max-width: 1025px;
  height: 135px;
  padding: 0 10px;
  background-color: rgba(255, 255, 255, 0.73);
  margin: 0 auto;

  // breakpoint(small)
  @media (min-width: 44.5em) {
    height: 116px;
    padding: 4px 20px 0 20px;
  }
`;

const SearchField = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 160px;
  margin: 0 auto;
  z-index: 1;

  &.no-image {
    height: unset;
  }

  &.no-image {
    ${SearchBox} {
      background-color: unset;
    }
  }
`;

const SearchWrapper = styled.div`
  padding: 0 20px;
`;

const SearchFormLeft = styled.div`
  // breakpoint(small)
  @media (min-width: 44.5em) {
    flex: 1;
    padding: 0 24px 0 0;
    margin: 0;
  }
`;

const SearchFormRight = styled.div`
  // breakpoint(small)
  @media (min-width: 44.5em) {
    width: 300px;
    padding: 0;
    margin: 0;
  }
`;

const SearchForm = styled.div`
  width: 100%;
  padding: 0;
  margin: 0;

  // breakpoint(small)
  @media (min-width: 44.5em) {
    display: flex;
    padding-top: 3px;
  }
`;

const SearchImageMobile = styled.picture``;

const SearchImageMobileImage = styled.img`
  display: block;
  height: auto;
  width: 100%;
  aspect-ratio: auto 1080 / 680;

  // breakpoint(small)
  @media (min-width: 44.5em) {
    display: none;
  }
`;

const SearchImageDesktop = styled.picture`
  display: none;

  // breakpoint(small)
  @media (min-width: 44.5em) {
    display: block;
    img {
      display: block;
      height: auto;
      width: 100%;
      aspect-ratio: auto 1600 / 482;
    }
  }

  max-width: 2100px;
  margin: 0 auto;
`;

const SearchImageDesktopImage = styled.img`
  width: 1600px;
  height: 482px;
`;

const SearchButton = styled.button`
  width: 100%;
  background-color: var(--button-color);
  display: block;
  font-weight: 700;
  color: var(--button-font);
  border: none;
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding: 0;
  height: 49px;
  cursor: pointer;

  // breakpoint(small)
  @media (min-width: 44.5em) {
    height: 69px;
    font-size: 18px;
    margin-top: 16px;
  }

  &:hover {
    background-color: var(--button-color-hover);
    transition: background-color 0.3s ease;
  }
`;

const SearchInputField = styled.div`
  display: flex;
  flex-flow: column-reverse;
  margin-bottom: 10px;
`;

const SearchLabel = styled.label`
  transition: all 0.2s;
  touch-action: manipulation;
  font-weight: 700;
  color: #585858;
  padding-left: 15px;
`;

const SearchInput = styled.input`
  transition: all 0.2s;
  touch-action: manipulation;
  color: $fontgrey;
  font-size: inherit;
  height: 49px;

  // breakpoint(small)
  @media (min-width: 44.5em) {
    height: 69px;
  }

  border: 2px solid black;
  padding: 15px;
  font-family: inherit;
  -webkit-appearance: none;
  border-radius: 0;
  cursor: text;
  color: black;

  &:focus {
    outline: 0;
    border: 2px solid var(--theme-color);
  }

  &:focus::-webkit-input-placeholder {
    opacity: 1;
  }

  &:placeholder-shown + ${SearchLabel} {
    cursor: text;
    max-width: 66.66%;
    white-space: nowrap;
    overflow: hidden;
    overflow: auto;
    padding-left: 15px;
    text-overflow: ellipsis;
    transform-origin: left bottom;
    font-size: 12px;
    transform: translate(0, 2.1rem) scale(1.2);

    // breakpoint(small)
    @media (min-width: 44.5em) {
      transform: translate(0, 2.8rem) scale(1.3);
      font-size: 14px;
    }
  }

  &:not(:placeholder-shown) + ${SearchLabel}, &:focus + ${SearchLabel} {
    transform: translate(0, 0) scale(1);
    cursor: pointer;
    font-size: 12px;

    // breakpoint(small)
    @media (min-width: 44.5em) {
      font-size: 14px;
    }
  }
`;

const Styled = {
  Search,
  SearchBackground,
  SearchField,
  SearchWrapper,
  SearchBox,
  SearchForm,
  SearchFormLeft,
  SearchFormRight,
  SearchImageMobile,
  SearchImageMobileImage,
  SearchImageDesktop,
  SearchImageDesktopImage,
  SearchButton,
  SearchInputField,
  SearchInput,
  SearchLabel,
};

export default Styled;
