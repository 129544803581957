import styled from "styled-components";

const NestedList = styled.div`
  padding: 20px;
  background-color: white;

  @media (max-width: 1024px) {
    padding: 8px 0 0 0;
    background-color: transparent;
  }

  a,
  a:visited,
  a:active {
    color: black;
    text-decoration: none;
    display: block;
    padding: 5px 2px;
  }

  a:hover {
    color: var(--button-color-hover);
  }

  ul {
    width: 100%;
    display: block;
    overflow: auto;

    li {
      font-size: 1em;
      font-weight: 700;
      width: 100%;
      display: block;
      overflow: auto;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      .text {
        padding: 7px 0 6px 4px;
        display: block;
        float: left;
        width: 170px;
        //overflow: auto;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .text.wide {
        width: 230px;
      }

      .number {
        float: right;
        padding: 6px 0;
        width: 40px;
        text-align: center;
        overflow: auto;
        display: block;
        background-color: white;
        border-radius: 40px;
        font-weight: 400;
      }

      @media (min-width: 1024px) {
        .number {
          line-height: 160%;
          background-color: var(--background-grey);
        }
      }

      .arrow {
        width: 30px;
        float: left;
        overflow: hidden;
        padding-right: 10px;
        display: block;
      }

      svg {
        transform: scale(2);
      }

      ul {
        font-size: 15px;
        width: 100%;
        display: block;
        overflow: auto;

        li {
          font-weight: 400;
          display: block;
          overflow: auto;

          .arrow {
            width: 50px;
            float: left;
            overflow: hidden;

            svg {
              float: right;
            }
          }

          .text {
            width: 150px;
          }

          .text.wide {
            width: 210px;
          }

          a {
            padding: 3px 2px;
          }

          svg {
            transform: scale(1.6);
          }

          ul {
            li {
              font-size: 12px;
              display: block;
              overflow: auto;
              padding: 5px 0 0 10px;

              a {
                padding: 8px 2px;
              }
            }
          }
        }
      }
    }

    .openfirst {
      .act,
      .act:visited {
        color: var(--dark-theme);

        svg {
          fill: var(--dark-theme);
        }
      }

      .open svg {
        transform: rotate(90deg) scale(2);
      }

      .opensecond .open svg {
        transform: rotate(90deg) scale(1.6);
      }
    }
  }
`;

const Styled = {
  NestedList,
};

export default Styled;
