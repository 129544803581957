import React, { useEffect, useState } from "react";
import { useJobDetailsStore } from "./JobDetailsStore";
import { observer } from "mobx-react";
import ThreeColumnLayout from "../../common/layout/ThreeColumnLayout";
import JobDetailsContent from "./JobDetailsContent";
import NestedList from "../../common/NestedList";
import JobDetailsNavigation from "./JobDetailsNavigation";
import TopEmployersWidget from "../../widgets/TopEmployersWidget";
import AdvertisementBannerWidget from "../../widgets/AdvertisementBannerWidget";
import { getWindowObj } from "../../../utils/window-utils";

const JobDetailsPage = observer(() => {
  const store = useJobDetailsStore();

  const {
    job,
    jobMailerUrl,
    companyProfileUrl,
    aggregations,
    openJobsByCategory,
  } = store;
  const [similarJobsUrl, setSimilarJobsUrl] = useState("");
  
  useEffect(() => {
    setSimilarJobsUrl((getWindowObj()?.location.pathname || '/') + "similar");
  }, []);

  return (
    <>
      <JobDetailsNavigation
        jobId={job.id}
        companyUrl={job.company?.name ? encodeURI(job.company.name) : ""}
        jobMailerUrl={jobMailerUrl}
        companyProfileUrl={companyProfileUrl}
        similarJobsUrl={similarJobsUrl}
      />
      <ThreeColumnLayout
        left={
          <>
            {aggregations && aggregations.length > 0 && (
              <NestedList
                nodes={aggregations}
                onNodeClick={openJobsByCategory}
              />
            )}
          </>
        }
        mid={
          <>
            {job && (
              <JobDetailsContent
                job={job}
                jobMailerUrl={jobMailerUrl}
                companyProfileUrl={companyProfileUrl}
                similarJobsUrl={similarJobsUrl}
              />
            )}
            {!job && <div className="loading-center">Loading...</div>}
          </>
        }
        right={
          <>
            <TopEmployersWidget />
            <AdvertisementBannerWidget />
          </>
        }
      />
    </>
  );
});

export default JobDetailsPage;
