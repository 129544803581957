import React, { useContext } from "react";
import { createContext, PropsWithChildren } from "react";

interface User {
  isStaff: boolean;
  isAdmin: boolean;
  permissions: string[];
}

export const UserContext = createContext<User>({
  isStaff: false,
  isAdmin: false,
  permissions: [],
});

interface UserProviderProps extends PropsWithChildren<{}> {
  user: User;
}

const UserProvider: React.FC<UserProviderProps> = ({ user, children }) => {
  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export function useCheckPermission(permission: string) {
  const user = useContext(UserContext);
  return user.isAdmin || user.permissions.includes(permission);
}

export default UserProvider;
