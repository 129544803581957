import React from "react";
import Select from "../../common/Select";
import Styled from "./styled";

interface JobSubmissionFormOrderProps {
  products: string[];
  selectedProduct?: string;

  selectProduct(product: string): void;
}

const JobSubmissionFormOrder: React.FC<JobSubmissionFormOrderProps> = ({
  products,
  selectedProduct,
  selectProduct,
}) => {
  return (
    <>
      <h2>
        Ihre Bestellung: Normal
        <Styled.JobSubmissionFormInfo>i</Styled.JobSubmissionFormInfo>
      </h2>
      <label htmlFor="inserattyp">
        <p>Günstiges Inserat mit hoher Sichtbarkeit. (Preise exkl. MWST)</p>
      </label>
      <Select
        selectedOption={selectedProduct}
        options={products}
        onSelect={selectProduct}
      />
      <hr />
    </>
  );
};

export default JobSubmissionFormOrder;
