import React, { useCallback, useEffect, useState } from "react";
import NestedList from "../common/NestedList";
import axios from "axios";
import { Node } from "../types";
import { createAggregations } from "../job/utils";
import {
  goToUrl,
  insertArrayParameter,
  insertSingleParam,
} from "../../utils/url-utils";

type Aggregation = "classification" | "region" | "company";

interface JobsAggregationsNestedListProps {
  types: Aggregation[];
}

const JobsAggregationsWidget: React.FC<JobsAggregationsNestedListProps> = ({
  types,
}) => {
  const [aggregations, setAggregations] = useState<Node[]>([]);

  const selectParameter = useCallback((node: Node) => {
    switch (node.key) {
      case "category":
        goToUrl(
          `/jobs/?${insertArrayParameter("classification", node.fullname)}`
        );
        break;
      case "region":
        goToUrl(`/jobs/?${insertSingleParam("region", node.label)}`);
        break;
      case "company":
        goToUrl(`/jobs/?${insertSingleParam("region", node.label)}`);
        break;
    }
  }, []);

  useEffect(() => {
    axios
      .get("/jobs-api/aggregations/", {
        params: {
          types,
        },
      })
      .then((response) => {
        const aggregations = createAggregations(response.data);
        let nodes: Node[] = [];
        if (aggregations.categories && aggregations.categories.length > 0) {
          nodes.push({
            label: "Category",
            fullname: "Category",
            showSubnodes: true,
            subnodes: aggregations.categories,
          });
        }

        if (aggregations.regions && aggregations.regions.length > 0) {
          nodes.push({
            label: "Region",
            fullname: "Region",
            showSubnodes: true,
            subnodes: aggregations.regions,
          });
        }

        if (aggregations.companies && aggregations.companies.length > 0) {
          nodes.push({
            label: "Company",
            fullname: "Company",
            showSubnodes: true,
            subnodes: aggregations.companies,
          });
        }

        setAggregations(nodes);
      });
  }, [types]);

  return <NestedList nodes={aggregations} onNodeClick={selectParameter} />;
};

export default JobsAggregationsWidget;
