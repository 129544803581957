import React from "react";
import Button from "../Button";
import Styled from "./styled";

interface PaginationLoadMoreProps {
  loadDisabled: boolean;

  load(): void;
}

const PaginationLoadMore: React.FC<PaginationLoadMoreProps> = ({
  loadDisabled,
  load,
}) => {
  return (
    <Styled.PaginationLoadMore>
      <Button
        label="Mehr laden"
        onClick={load}
        disabled={loadDisabled}
        primary
      />
    </Styled.PaginationLoadMore>
  );
};

export default PaginationLoadMore;
