import React, { useContext } from "react";
import { ConfigContext } from "../../../providers/ConfigProvider";
import Wrapper from "../../common/Wrapper";

const JobSubmissionFormConfirmation: React.FC = () => {
  const portalEmail = useContext(ConfigContext)?.email;
  return (
    <>
      <Wrapper>
        <h1 className="h1link">Vielen Dank für Ihren Auftrag</h1>
      </Wrapper>
      <div className="list">
        <div className="simpletext textcontent clearbox">
          <section>
            <p>
              Eine Kopie Ihres Inserate-Auftrages finden Sie in Ihrer
              Email-Inbox. Sobald das Inserat aufgeschaltet ist, erhalten Sie
              eine weitere Bestätigung von uns.
              <br />
              <br />
              Bei Fragen kontaktieren Sie uns bitte unter:{" "}
              <i>
                <a href={`mailto:${portalEmail}`}>{portalEmail}</a>
              </i>
              .
            </p>
          </section>
        </div>
      </div>
    </>
  );
};

export default JobSubmissionFormConfirmation;
