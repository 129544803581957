import React from "react";
import { JobMailerProfileStoreProvider } from "./JobMailerProfileStore";
import JobMailerProfilePage from "./JobMailerProfilePage";

interface JobMailerProfileRootProps {
  jobId: number | null;
}

const JobMailerProfileRoot: React.FC<JobMailerProfileRootProps> = ({
  jobId,
}) => {
  return (
    <JobMailerProfileStoreProvider jobId={jobId}>
      <JobMailerProfilePage />
    </JobMailerProfileStoreProvider>
  );
};

export default JobMailerProfileRoot;
