export const getUrlParameter = (parameterName: string): string | undefined => {
  let result: string | undefined = undefined;
  let tmp = [];
  window.location.search
    .substr(1)
    .split("&")
    .forEach((item: string) => {
      tmp = item.split("=");
      if (tmp[0] === parameterName) {
        result = decodeURIComponent(tmp[1]);
      }
    });
  return result;
};

export const removeUrlSearchParameter = (
  urlSearchPart: string,
  parameter: string
): string => {
  const parts = urlSearchPart.split("&");
  const prefix = encodeURIComponent(parameter) + "=";

  for (let i = parts.length - 1; i >= 0; i--) {
    if (parts[i].startsWith(prefix)) {
      parts.splice(i, 1);
    }
  }

  return parts.join("&");
};

export const pushUrl = (newUrl: string) => {
  window.history.pushState(null, "", newUrl);
};

export const goToUrl = (url: string) => {
  window.location.href = url;
};

export const goBack = (url: string) => {
  window.location.href = url;
};

export const insertSingleParam = (key: string, value: string): string => {
  key = encodeURIComponent(key);
  value = encodeURIComponent(value);

  const keyValueItems = document.location.search
    .substr(1)
    .split("&")
    .filter(Boolean);

  let i = 0;
  for (; i < keyValueItems.length; i++) {
    if (keyValueItems[i].startsWith(key + "=")) {
      let pair = keyValueItems[i].split("=");
      pair[1] = value;
      keyValueItems[i] = pair.join("=");
      break;
    }
  }

  if (i >= keyValueItems.length) {
    keyValueItems[keyValueItems.length] = [key, value].join("=");
  }

  return keyValueItems.join("&");
};

export const insertArrayParameter = (key: string, value: string): string => {
  key = encodeURIComponent(key);
  value = encodeURIComponent(value);

  const keyValueItems = document.location.search
    .substr(1)
    .split("&")
    .filter(Boolean);

  let i = 0;
  for (; i < keyValueItems.length; i++) {
    if (keyValueItems[i].startsWith(key + "=")) {
      let pair = keyValueItems[i].split("=");
      pair[1] = !pair[1] ? value : `${pair[1]},${value}`;
      keyValueItems[i] = pair.join("=");
      break;
    }
  }

  if (i >= keyValueItems.length) {
    keyValueItems[keyValueItems.length] = [key, value].join("=");
  }

  return keyValueItems.join("&");
};

export const removeUrlSearchArrayParameter = (
  key: string,
  value: string
): string => {
  key = encodeURIComponent(key);
  value = encodeURIComponent(value);

  const keyValueItems = document.location.search
    .substr(1)
    .split("&")
    .filter(Boolean);

  let i = 0;
  for (; i < keyValueItems.length; i++) {
    if (keyValueItems[i].startsWith(key + "=")) {
      let pair = keyValueItems[i].split("=");
      pair[1] = pair[1]
        .split(",")
        .filter((item) => item !== value)
        .join(",");
      keyValueItems[i] = pair.join("=");
      break;
    }
  }

  if (i >= keyValueItems.length) {
    keyValueItems[keyValueItems.length] = [key, value].join("=");
  }

  return keyValueItems.join("&");
};
