import React from "react";
import Wrapper from "../../../common/Wrapper";
import JobListItem from "../../JobListItem";
import { Job } from "../../types";
import Styled from "./styled";

interface PremiumJobListProps {
  jobs?: Job[];
}

const JobSimilarList: React.FC<PremiumJobListProps> = ({ jobs }) => {
  return (
    <>
      <Wrapper>
        <h1>Ähnliche Jobs</h1>
      </Wrapper>
      {jobs && jobs.length > 0 && (
        <Styled.JobSimilarList>
          {jobs.map((job) => (
            <JobListItem job={job} />
          ))}
        </Styled.JobSimilarList>
      )}
      {!jobs && <div className="center">Loading...</div>}
      {jobs && jobs.length === 0 && (
        <div className="center">Keine offenen Stellen</div>
      )}
    </>
  );
};

export default JobSimilarList;
