import React, { useContext } from "react";
import { action, computed, observable, reaction, makeObservable } from "mobx";
import { getJsonItem, setJsonItem } from "../../../utils/localstorage-utils";
import axios from "axios";
import {
  getUrlParameter,
  insertArrayParameter,
  insertSingleParam,
  removeUrlSearchArrayParameter,
  removeUrlSearchParameter,
  pushUrl,
} from "../../../utils/url-utils";
import { Job, JobAggregations } from "../types";
import { createAggregations, getJobLevelName, getJobTypeName } from "../utils";
import { Node } from "../../types";
import { ConfigContext } from "../../../providers/ConfigProvider";
import { getSlug } from "../../../utils/string-utils";

const defaultJobAggregations: JobAggregations = {
  categories: [],
  regions: [],
  companies: [],
  jobTypes: [],
  jobLevels: [],
};

const INITIAL_PAGE = 1;

export class JobAllStore {
  private readonly dataServiceUrl: string;
  private readonly portal: string;
  private readonly jobsPerPage: number;
  @observable
  private classificationSlug: string | null;
  @observable
  private regionSlug: string | null;
  @observable
  private slugDictionary: any;
  @observable jobs?: Job[];
  @observable query?: string = getUrlParameter("q");
  @observable categories?: string[] = getUrlParameter("classification")?.split(",").filter(Boolean);
  @observable region?: string = getUrlParameter("region");
  @observable company?: string = getUrlParameter("company");
  @observable jobTypes?: string[] = getUrlParameter("jobType")?.split(",").filter(Boolean);
  @observable jobLevel?: string = getUrlParameter("jobLevel");
  @observable page: number = INITIAL_PAGE;
  @observable totalCount?: number;
  @observable aggregations: JobAggregations = defaultJobAggregations;
  @observable lastSearches: string[] = getJsonItem("itj-last-searches") || [];
  @observable classificationsData: any[] = [];
  @observable regionsData: any[] = [];
  @observable canLoadMore: boolean = true;

  constructor(
    classificationSlug: string | null,
    regionSlug: string | null,
    dataServiceUrl: string,
    portal: string,
    jobsPerPage: number
  ) {
    makeObservable(this);
    this.classificationSlug = classificationSlug;
    this.regionSlug = regionSlug;
    this.dataServiceUrl = dataServiceUrl;
    this.portal = portal;
    this.jobsPerPage = jobsPerPage;
    this.init();
  }

  readonly init = () => {
    this.loadData().then(action(this.reloadJobs));

    reaction(() => this.query, this.reloadJobs);
    reaction(() => this.categories, this.reloadJobs);
    reaction(() => this.region, this.reloadJobs);
    reaction(() => this.company, this.reloadJobs);
    reaction(() => this.jobLevel, this.reloadJobs);
    reaction(() => this.jobTypes, this.reloadJobs);
    reaction(() => this.jobListTitle, this.updatePageMeta);

    window.addEventListener("popstate", this.updateParameters);
  };

  @computed get selectedParameters() {
    return [
      ...(this.categories?.map((category) => category.replace("::", " > ")) ||
        []),
      this.region,
      this.company,
      ...(this.jobTypes || []).map(getJobTypeName),
      this.jobLevel ? getJobLevelName(this.jobLevel) : undefined,
    ]
      .filter((item) => !!item)
      .map((item) => item!);
  }

  @computed get jobListTitle() {
    const classificationName =
      this.classificationSlug && this.classificationSlug !== "any"
        ? this.restoreName(this.classificationSlug)
        : undefined;
    const regionName = this.regionSlug
      ? this.restoreName(this.regionSlug)
      : undefined;

    const classifications = classificationName
      ? [classificationName]
      : this.categories;
    const region = regionName || this.region;

    return `${this.query || ""} ${
      (classifications || []).map((category) => {
        const parts = category.split("::");
        return parts[parts.length - 1];
      }) || ""
    } Jobs ${region ? `in der Region ${region}` : ""} ${
      this.company ? `bei ${this.company}` : ""
    }`.trim();
  }

  @computed get aggregationsList(): Node[] {
    const nodes: Node[] = [];
    const { aggregations } = this;
    if (aggregations.categories && aggregations.categories.length > 0) {
      nodes.push({
        label: "Category",
        fullname: "Category",
        showSubnodes: true,
        subnodes: aggregations.categories,
      });
    }

    if (aggregations.jobTypes && aggregations.jobTypes.length > 0) {
      nodes.push({
        label: "Job Type",
        fullname: "Job Type",
        showSubnodes: false,
        subnodes: aggregations.jobTypes,
      });
    }

    if (aggregations.jobLevels && aggregations.jobLevels.length > 0) {
      nodes.push({
        label: "Job Level",
        fullname: "Job Level",
        showSubnodes: false,
        subnodes: aggregations.jobLevels,
      });
    }

    if (aggregations.regions && aggregations.regions.length > 0) {
      nodes.push({
        label: "Region",
        fullname: "Region",
        showSubnodes: true,
        subnodes: aggregations.regions,
      });
    }

    if (aggregations.companies && aggregations.companies.length > 0) {
      nodes.push({
        label: "Company",
        fullname: "Company",
        showSubnodes: false,
        subnodes: aggregations.companies,
      });
    }
    
    return nodes;
  }

  @action
  readonly setQuery = (query: string) => {
    this.query = query;
    if (query && query.length > 0) {
      let searches = [...this.lastSearches, query].filter(
        (search, index, self) => self.indexOf(search) === index
      );
      if (searches.length > 10) {
        searches = searches.slice(1);
      }
      this.lastSearches = searches;
      setJsonItem("itj-last-searches", searches);
    }
    pushUrl(`/jobs/?${insertSingleParam("q", query || "")}`);
  };

  @action readonly selectParameter = (node: Node) => {
    this.classificationSlug = null;
    this.regionSlug = null;

    switch (node.key) {
      case "category":
        if (!this.categories?.includes(node.fullname)) {
          this.categories = [...(this.categories || []), node.fullname];
          pushUrl(
            `/jobs/?${insertArrayParameter("classification", node.fullname)}`
          );
        }
        break;
      case "region":
        this.region = node.label;
        pushUrl(`/jobs/?${insertSingleParam("region", node.label)}`);
        break;
      case "company":
        this.company = node.label;
        pushUrl(`/jobs/?${insertSingleParam("company", node.label)}`);
        break;
      case "jobType":
        if (!this.jobTypes?.includes(node.fullname)) {
          this.jobTypes = [...(this.jobTypes || []), node.fullname];
          pushUrl(
            `/jobs/?${insertArrayParameter("jobType", node.fullname)}`
          );
        }
        break;
      case "jobLevel":
        this.jobLevel = node.fullname;
        pushUrl(`/jobs/?${insertSingleParam("jobLevel", node.fullname)}`);
        break;
    }
  };

  @action readonly removeLastSearch = (query: string) => {
    const searches = this.lastSearches.filter((search) => search !== query);
    this.lastSearches = searches;
    setJsonItem("itj-last-searches", searches);
  };

  @action readonly clearLastSearches = () => {
    this.lastSearches = [];
    setJsonItem("itj-last-searches", []);
  };

  @action readonly removeSelectedParameter = (selectedParameter: string) => {
    let removedParameter: string | undefined = undefined;
    const parameter = selectedParameter.replace(" > ", "::");

    if (this.categories?.includes(parameter)) {
      this.categories = this.categories.filter(
        (category) => category !== parameter
      );
      const newSearchUrlPart = removeUrlSearchArrayParameter(
        "classification",
        parameter
      );
      pushUrl(!newSearchUrlPart ? "/jobs" : `/jobs/?${newSearchUrlPart}`);
      return;
    } else if (parameter === this.region) {
      this.region = undefined;
      removedParameter = "region";
    } else if (parameter === this.company) {
      this.company = undefined;
      removedParameter = "company";
    } else if (this.jobTypes && this.jobTypes.map(getJobTypeName).includes(parameter)) {
      this.jobTypes = this.jobTypes.filter(
        (jobType) => getJobTypeName(jobType) !== parameter
      );
      const newSearchUrlPart = removeUrlSearchArrayParameter(
        "jobType",
        parameter
      );
      pushUrl(!newSearchUrlPart ? "/jobs" : `/jobs/?${newSearchUrlPart}`);
    } else if (this.jobLevel && (parameter === getJobLevelName(this.jobLevel))) {
      this.jobLevel = undefined;
      removedParameter = "jobLevel";
    }

    if (!!removedParameter) {
      const newSearchUrlPart = removeUrlSearchParameter(
        window.location.search.substr(1),
        removedParameter
      );
      pushUrl(!newSearchUrlPart ? "/jobs" : `/jobs/?${newSearchUrlPart}`);
    }
  };

  @action readonly clearSelectedParameters = () => {
    this.categories = undefined;
    this.region = undefined;
    this.company = undefined;
    this.jobTypes = undefined;
    this.jobLevel = undefined;
    pushUrl("/jobs");
  };

  @action
  readonly loadMoreJobs = () => {
    this.page++;
    this.loadJobs(false);
  };

  private readonly updatePageMeta = () => {
    document.title = this.jobListTitle;
    (
      document.head.querySelector('meta[name="description"]') as HTMLMetaElement
    ).content = this.jobListTitle;
  };

  private readonly updateParameters = () => {
    this.classificationSlug = null;
    this.regionSlug = null;
    const parts = window.location.pathname.split("/").filter((part) => !!part);
    if (parts.length === 3) {
      this.classificationSlug = parts[1];
      this.regionSlug = parts[2];
    }
    this.query = getUrlParameter("q");
    this.categories = getUrlParameter("classification")?.split(",").filter(Boolean);
    this.region = getUrlParameter("region");
    this.company = getUrlParameter("company");
    this.jobTypes = getUrlParameter("jobType")?.split(",").filter(Boolean);
    this.jobLevel = getUrlParameter("jobLevel");
  };

  private readonly reloadJobs = () => {
    this.page = INITIAL_PAGE;
    this.loadJobs(true);
  };

  private readonly loadJobs = (clearExisting?: boolean) => {
    if (clearExisting) {
      this.jobs = undefined;
      this.totalCount = undefined;
    } else {
      this.canLoadMore = false;
    }

    const classificationName =
      this.classificationSlug && this.classificationSlug !== "any"
        ? [this.restoreName(this.classificationSlug)]
        : undefined;
    const regionName = this.regionSlug
      ? this.restoreName(this.regionSlug)
      : undefined;

    axios
      .get("/jobs-api/", {
        params: {
          start: (this.page - 1) * this.jobsPerPage,
          query: this.query,
          company: this.company,
          region: regionName || this.region,
          classifications: classificationName || this.categories,
          jobTypes: this.jobTypes,
          jobLevel: this.jobLevel,
        },
      })
      .then(
        action((response) => {
          if (clearExisting) {
            this.jobs = response.data.list;
          } else {
            this.jobs = [...this.jobs!, ...response.data.list];
            this.canLoadMore = true;
          }
          this.totalCount = response.data.totalCount;
          this.aggregations = createAggregations(response.data.aggregations);
        })
      );
  };

  @action readonly loadData = async () => {
    this.classificationsData = (
      await axios.get(`${this.dataServiceUrl}/classifications`)
    ).data[this.portal];
    this.regionsData = (
      await axios.get(`${this.dataServiceUrl}/regions`)
    ).data;
    this.ensureSlugDictionary({
      classifications: this.classificationsData,
      regions: this.regionsData,
    });
  };

  private ensureSlugDictionary(data: { classifications: any; regions: any }) {
    if (!this.slugDictionary) {
      this.slugDictionary = {};

      this.traverseData(
        [data.regions, data.classifications],
        (name: string) => {
          const slug = getSlug(name);
          if (slug && slug !== name) {
            this.slugDictionary[slug] = name;
          }
        }
      );
    }
  }

  private traverseData(element: any, callback: (name: string) => void) {
    const name = Array.isArray(element) ? null : element.name;
    const children = Array.isArray(element) ? element : element.children;

    if (name) {
      callback(name);
    }

    if (children && children.length > 0) {
      children.forEach((child: any) => {
        this.traverseData(child, callback);
      });
    }
  }

  private restoreName(slug: string) {
    return this.slugDictionary && this.slugDictionary[slug];
  }
}

const storeContext = React.createContext<JobAllStore | null>(null);

export const JobAllStoreProvider = ({
  classificationSlug,
  regionSlug,
  jobsPerPage,
  children,
}: React.PropsWithChildren<{
  classificationSlug: string | null;
  regionSlug: string | null;
  jobsPerPage: number;
}>) => {
  const dataServiceUrl = useContext(ConfigContext)?.dataServiceUrl!;
  const portal = useContext(ConfigContext)?.instance!;
  const store = new JobAllStore(
    classificationSlug,
    regionSlug,
    dataServiceUrl,
    portal,
    jobsPerPage
  );
  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
};

export const useJobAllStore = () => {
  const store = React.useContext(storeContext);
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error(
      "useJobAllStore must be used within a JobAllStoreProvider."
    );
  }
  return store;
};
