import React from "react";
import { observer } from "mobx-react";
import { useJobSubmissionFormStore } from "./JobSubmissionFormStore";
import OneColumnLayout from "../../common/layout/OneColumnLayout";
import JobSubscriptionForm from "./JobSubscriptionForm";
import ThreeColumnLayout from "../../common/layout/ThreeColumnLayout";
import JobSubmissionFormConfirmation from "./JobSubmissionFormConfirmation";

const JobSubmissionFormPage = observer(function JobSubmissionFormPage() {
  const store = useJobSubmissionFormStore();

  const {
    products,
    selectedProduct,
    documents,
    email,
    comment,
    showConfirmation,
    disableSubmit,
    isFormEmpty,
    error,
    selectProduct,
    setEmail,
    setComment,
    updateDocument,
    processFormData,
  } = store;

  return (
    <>
      {!showConfirmation && (
        <OneColumnLayout>
          <div className="miniwrapper">
            <h1>Stelleninserat hochladen</h1>
          </div>
          <div className="floater">
            <JobSubscriptionForm
              products={products}
              selectedProduct={selectedProduct}
              documents={documents}
              email={email}
              comment={comment}
              submitDisabled={disableSubmit}
              isFormEmpty={isFormEmpty}
              error={error}
              selectProduct={selectProduct}
              updateEmail={setEmail}
              updateComment={setComment}
              updateDocument={updateDocument}
              submitForm={processFormData}
            />
          </div>
        </OneColumnLayout>
      )}
      {showConfirmation && (
        <ThreeColumnLayout
          left={null}
          mid={<JobSubmissionFormConfirmation />}
          right={null}
        />
      )}
    </>
  );
});

export default JobSubmissionFormPage;
