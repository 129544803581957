import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Node, NodesGroup } from "../../types";
import Link from "../Link";
import { useWindowSize } from "../../../utils/hooks";
import Styled from "./styled";

interface NestedListProps {
  nodes: Node[] | NodesGroup[];

  onNodeClick?(node: Node): void;
}

const NestedList: React.FC<NestedListProps> = ({ nodes, onNodeClick }) => {
  const windowWidth = useWindowSize().width;

  return (
    <Styled.NestedList>
      <NestedListNodes
        nodes={nodes}
        hideNodes={windowWidth < 1024}
        onNodeClick={onNodeClick}
      />
    </Styled.NestedList>
  );
};

interface NestedListNodesProps extends PropsWithChildren<{}> {
  nodes: Node[] | NodesGroup[];
  hideNodes: boolean;

  onNodeClick?(node: Node): void;
}

const NestedListNodes: React.FC<NestedListNodesProps> = ({
  nodes,
  hideNodes,
  onNodeClick,
}) => {
  return (
    <ul>
      {nodes.map((node, index) => (
        <React.Fragment key={index}>
          {!Array.isArray(node) && (
            <NestedListNode
              node={node}
              hideNodes={hideNodes}
              onClick={onNodeClick}
            />
          )}
          {Array.isArray(node) && (
            <>
              {node.map((n) => (
                <NestedListNode
                  key={n.key}
                  node={n}
                  hideNodes={hideNodes}
                  onClick={onNodeClick}
                />
              ))}
              {index !== nodes.length - 1 && (
                <hr style={{ marginTop: "0", marginBottom: "10px" }} />
              )}
            </>
          )}
        </React.Fragment>
      ))}
    </ul>
  );
};

interface NestedListNodeProps extends PropsWithChildren<{}> {
  node: Node;
  hideNodes: boolean;

  onClick?(node: Node): void;
}

const NestedListNode: React.FC<NestedListNodeProps> = ({
  node,
  hideNodes,
  onClick,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setOpen(!hideNodes && node.showSubnodes);
  }, [hideNodes, node]);

  const handleToggleNode = useCallback(() => {
    if (node.subnodes.length > 0) {
      setOpen(!open);
    }
  }, [node, open]);

  const handleClick = useCallback(() => {
    onClick && onClick(node);
  }, [node, onClick]);

  return (
    <li className={`${open ? "openfirst" : ""}`}>
      <Link
        className={`arrow ${open ? "open act" : "closed"}`}
        onClick={handleToggleNode}
      >
        <svg className="icon opener">
          <use xlinkHref="#opener" />
        </svg>
      </Link>
      <Link
        className={`text ${open ? "act" : ""} ${
          node.number ? "" : "wide"
        }`}
        onClick={handleClick}
      >
        {node.label}
      </Link>
      {node.number && <span className="number">{node.number}</span>}
      {node.subnodes.length > 0 && open && (
        <NestedListNodes
          nodes={node.subnodes}
          hideNodes={hideNodes}
          onNodeClick={onClick}
        />
      )}
    </li>
  );
};

export default NestedList;
