import React, { useCallback } from "react";
import { formatDateDots } from "../../../utils/date-utils";
import Link from "../../common/Link";
import { JobDetails } from "../types";

interface JobDetailsDescriptionHeaderProps {
  job: JobDetails;
  companyProfileUrl: string;
}

const JobDetailsDescriptionHeader: React.FC<JobDetailsDescriptionHeaderProps> =
  ({ job, companyProfileUrl }) => {
    const handleLogoOnload = useCallback((e: any) => {
      const companyLogo = e.target;
      const height = companyLogo.naturalHeight;
      const width = companyLogo.naturalWidth;
      const coef = width / height;
      if (height >= width) {
        companyLogo.height = 120;
        companyLogo.width = 120 * coef;
      } else {
        companyLogo.width = 190;
        companyLogo.height = 190 / coef;
      }
    }, []);

    return (
      <section className="headdet">
        <div className="dleft">
          {job.company && job.company.name && <h2>{job.company.name}</h2>}
          <p>
            {job.workplace && job.workplace.city && (
              <>
                <svg className="icon location">
                  <use xlinkHref="#location" />
                </svg>
                <span className="mr-5">{job.workplace.city}</span>
              </>
            )}
            {job.publishing && job.publishing.from && (
              <span className="mr-5">
                {formatDateDots(job.publishing.from)}
              </span>
            )}
            {companyProfileUrl && (
              <>
                <span className="mr-5">|</span>
                <svg className="icon business">
                  <use xlinkHref="#business" />
                </svg>
                <Link className="pointer" href={companyProfileUrl}>
                  Firma ansehen
                </Link>
              </>
            )}
          </p>
        </div>
        <div className="dright">
          <Link className="image">
            <picture className="customerlogo">
              <img
                id="company-logo"
                src={job.accountId ? `/companies/image/${job.accountId}.png` : undefined}
                alt={job.company?.name ? `${job.company.name} logo` : "Company logo"}
                onLoad={handleLogoOnload}
                width={0}
              />
            </picture>
          </Link>
        </div>
      </section>
    );
  };

export default JobDetailsDescriptionHeader;
