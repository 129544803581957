import React from "react";
import { action, observable, reaction, makeObservable } from "mobx";
import axios from "axios";
import { useCheckPermission } from "../../../providers/UserProvider";

export class CompanyListStore {
  private readonly companiesPerPage: number;
  @observable randomCompanies: any[] = [];
  @observable searchCompanies: any[] = [];
  @observable searchCompaniesTotal: number = 0;
  @observable selectedLetter: string | undefined = undefined;
  @observable page: number = 1;
  @observable isCreationAvailable: boolean;
  @observable isCreationDialogShown: boolean = false;
  @observable canLoadCompanies: boolean = true;

  constructor(isCreationAvailable: boolean, companiesPerPage: number) {
    makeObservable(this);
    this.isCreationAvailable = isCreationAvailable;
    this.companiesPerPage = companiesPerPage;
    this.init();
  }

  readonly init = () => {
    this.loadRandomCompanies();
    this.reloadCompanies();
    reaction(() => this.selectedLetter, this.reloadCompanies);
  };

  @action readonly setSelectedLetter = (letter: string) => {
    this.selectedLetter = letter;
  };

  @action readonly showCreationDialog = () => {
    this.isCreationDialogShown = true;
  };

  @action readonly hideCreationDialog = () => {
    this.isCreationDialogShown = false;
    this.reloadCompanies();
  };

  @action
  readonly loadMoreCompanies = () => {
    this.page++;
    this.loadCompanies(false);
  };

  private readonly reloadCompanies = () => {
    this.page = 1;
    this.loadCompanies(true);
  };

  private readonly loadCompanies = (clearExisting: boolean) => {
    axios
      .get("/companies/list/", {
        params: {
          page: this.page,
          limit: this.companiesPerPage,
          letter: this.selectedLetter,
        },
      })
      .then(
        action((response) => {
          if (clearExisting) {
            this.searchCompanies = response.data.companies;
          } else {
            this.searchCompanies = [
              ...this.searchCompanies,
              ...response.data.companies,
            ];
          }
          this.searchCompaniesTotal = response.data.totalCount;
        })
      );
  };

  @action
  readonly loadRandomCompanies = () => {
    axios.get("/companies/random/").then(
      action((response) => {
        this.randomCompanies = response.data;
      })
    );
  };
}

const storeContext = React.createContext<CompanyListStore | null>(null);

export const CompanyListStoreProvider = ({
  companiesPerPage,
  children,
}: React.PropsWithChildren<{ companiesPerPage: number }>) => {
  const isCreationAvailable = useCheckPermission("add_companyprofile");
  const store = new CompanyListStore(isCreationAvailable, companiesPerPage);
  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
};

export const useCompanyListStore = () => {
  const store = React.useContext(storeContext);
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error(
      "useCompanyListStore must be used within a CompanyListStoreProvider."
    );
  }
  return store;
};
