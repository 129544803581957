import React from "react";
import HorizontalTagsList from "../../common/HorizontalTagsList";
import ThreeColumnLayout from "../../common/layout/ThreeColumnLayout";
import JobList from "./JobList";
import { observer } from "mobx-react";
import { useJobAllStore } from "./JobAllStore";
import Search from "../Search";
import NestedList from "../../common/NestedList";
import TopEmployersWidget from "../../widgets/TopEmployersWidget";
import AdvertisementBannerWidget from "../../widgets/AdvertisementBannerWidget";

const JobAllPage = observer(function JobAllPage() {
  const store = useJobAllStore();

  const {
    jobs,
    query,
    totalCount,
    lastSearches,
    aggregationsList,
    selectedParameters,
    jobListTitle,
    canLoadMore,
    setQuery,
    removeLastSearch,
    clearLastSearches,
    removeSelectedParameter,
    clearSelectedParameters,
    selectParameter,
    loadMoreJobs,
  } = store;
  return (
    <>
      <Search onSearch={setQuery} defaultQuery={query} />
      {lastSearches.length > 0 && (
        <HorizontalTagsList
          icon="time"
          label="Letzten Suchanfragen:"
          names={lastSearches}
          onTagClick={setQuery}
          onRemoveTagClick={removeLastSearch}
          onClearAllClick={clearLastSearches}
        />
      )}
      {selectedParameters.length > 0 && (
        <HorizontalTagsList
          icon="filter"
          label="Ausgewählte Filter:"
          removeLabel="Zurücksetzen"
          names={selectedParameters}
          onRemoveTagClick={removeSelectedParameter}
          onClearAllClick={clearSelectedParameters}
        />
      )}
      <ThreeColumnLayout
        left={
          <NestedList nodes={aggregationsList} onNodeClick={selectParameter} />
        }
        mid={
          <JobList
            title={jobListTitle}
            jobs={jobs}
            totalCount={totalCount}
            canLoadMore={canLoadMore}
            loadJobs={loadMoreJobs}
          />
        }
        right={
          <>
            <TopEmployersWidget />
            <AdvertisementBannerWidget />
          </>
        }
      />
    </>
  );
});

export default JobAllPage;
