import React from "react";
import JobListItem from "../JobListItem";
import { Job } from "../types";
import Styled from "./styled";

interface AllJobListProps {
  jobs: Job[];
}

const JobAllList: React.FC<AllJobListProps> = ({ jobs }) => {
  return (
    <>
      {jobs.length > 0 && (
        <Styled.JobAllList>
          {jobs.map((job: Job) => (
            <JobListItem job={job} />
          ))}
        </Styled.JobAllList>
      )}
      {jobs.length === 0 && <div className="center">Keine offenen Stellen</div>}
    </>
  );
};

export default JobAllList;
