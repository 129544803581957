import React, { useContext } from "react";
import { action, computed, makeObservable, observable, toJS } from "mobx";
import axios from "axios";
import { ConfigContext } from "../../../providers/ConfigProvider";

export class JobMailerProfileStore {
  private readonly dataServiceUrl: string;
  private readonly jobId: number | null;
  private readonly portal: string;

  @observable profile: any;
  @observable classificationsData: any[] = [];
  @observable regionsData: any[] = [];
  @observable selectedClassifications: string[] = [];
  @observable selectedRegions: string[] = [];
  @observable error?: string;
  @observable showSuccessMessage: boolean = false;

  constructor(jobId: number | null, dataServiceUrl: string, portal: string) {
    makeObservable(this);
    this.jobId = jobId;
    this.dataServiceUrl = dataServiceUrl;
    this.portal = portal;
    this.loadProfile()
      .then(action(() => this.loadData()))
      .then(() => {
        if (this.jobId) {
          this.loadJobPreferences();
        }
      });
  }

  @computed get showExpiration() {
    return this.error === "expired";
  }

  @computed get classifications() {
    return this.mapData(toJS(this.classificationsData));
  }

  @computed get regions() {
    return this.mapData(toJS(this.regionsData));
  }

  @action updateSelectedClassifications = (classifications: string[]) => {
    this.selectedClassifications = classifications;
  };

  @action updateSelectedRegions = (regions: string[]) => {
    this.selectedRegions = regions;
  };

  @action readonly submit = () => {
    this.saveProfile(
      toJS(this.selectedClassifications),
      toJS(this.selectedRegions)
    );
  };

  @action readonly saveProfile = (
    classifications: string[],
    regions: string[]
  ) => {
    this.showSuccessMessage = false;
    this.error = undefined;
    axios
      .post("/job-mailer-api/save-profile/", {
        classifications,
        regions,
      })
      .then((response) => {
        this.profile = response.data;
        this.selectedClassifications = response.data.classifications;
        this.selectedRegions = response.data.regions;
        this.showSuccessMessage = true;
      })
      .catch((error) => {
        this.error = error.response.data.error;
      });
  };

  @action readonly onDeactivate = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Möchten Sie sich abmelden?")) {
      window.location = this.profile.deactivationUrl;
    }
  };

  @action readonly loadProfile = () => {
    return axios
      .post("/job-mailer-api/load-profile/")
      .then((response) => {
        this.profile = response.data;
        this.selectedClassifications = response.data.classifications;
        this.selectedRegions = response.data.regions;
      })
      .catch((error) => {
        this.error = error.response.data.error;
      });
  };

  @action readonly loadJobPreferences = () => {
    axios
      .post(`/job-mailer-api/get-job-preferences/`, {
        jobId: this.jobId,
      })
      .then((response) => {
        const classifications: string[] = response.data.classifications || [];
        const region = response.data.regions;
        classifications.forEach((classification) => {
          if (!this.selectedClassifications.includes(classification)) {
            this.selectedClassifications.push(classification);
          }
        });
        if (!this.selectedRegions.includes(region)) {
          this.selectedRegions.push(region);
        }
      })
      .catch((error) => {
        this.error = error.response.data.error;
      });
  };

  @action readonly loadData = async () => {
    try {
      if (!this.classificationsData.length) {
        this.classificationsData = (
          await axios.get(`${this.dataServiceUrl}/classifications`)
        ).data[this.portal];
      }
      if (!this.regionsData.length) {
        this.regionsData = (
          await axios.get(`${this.dataServiceUrl}/regions`)
        ).data;
      }
    } catch (error: any) {
      this.error = error.response.data.error;
    }
  };

  private readonly mapData = (
    data: any[],
    parentPath?: string,
    parentChecked?: boolean
  ): any[] => {
    return data.map((datum) => {
      const fullPath: string = parentPath
        ? `${parentPath}::${datum.name}`
        : datum.name;
      const checked: boolean =
        parentChecked ||
        this.selectedClassifications.includes(fullPath) ||
        this.selectedRegions.includes(fullPath);
      return {
        label: datum.name,
        checked: checked,
        fullPath: fullPath,
        children:
          datum.children && datum.children.length > 0
            ? this.mapData(datum.children, fullPath, checked)
            : [],
      };
    });
  };
}

const storeContext = React.createContext<JobMailerProfileStore | null>(null);

export const JobMailerProfileStoreProvider = ({
  jobId,
  children,
}: React.PropsWithChildren<{ jobId: number | null }>) => {
  const dataServiceUrl = useContext(ConfigContext)?.dataServiceUrl!;
  const portal = useContext(ConfigContext)?.instance!;
  const store = new JobMailerProfileStore(jobId, dataServiceUrl, portal);
  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
};

export const useJobMailerProfileStore = () => {
  const store = React.useContext(storeContext);
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error(
      "useJobMailerProfileStore must be used within a JobMailerProfileStoreProvider."
    );
  }
  return store;
};
