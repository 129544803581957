import React from "react";
import Wrapper from "../../common/Wrapper";
import Link from "../../common/Link";
import { JobDetails } from "../types";

interface JobDetailsContentHeaderProps {
  job: JobDetails;
}

const JobDetailsContentHeader: React.FC<JobDetailsContentHeaderProps> = ({
  job,
}) => {
  return (
    <>
      <Wrapper>
        <h1 className="h1link">
          {job.title || ""}
          <span className="btnspan">
            {!!job.fileUrl && (
              <Link
                href={job.fileUrl}
                target="_blank"
                rel="noopener"
                eventId="Outbound_Link_Originalinserat"
              >
                Originalinserat
              </Link>
            )}
          </span>
        </h1>
      </Wrapper>
    </>
  );
};

export default JobDetailsContentHeader;
