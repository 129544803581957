import React, { PropsWithChildren } from "react";
import Styled from "./styled";

interface WrapperProps extends PropsWithChildren<{}> {}

const Wrapper: React.FC<WrapperProps> = ({ children }) => {
  return <Styled.Wrapper>{children}</Styled.Wrapper>;
};

export default Wrapper;
