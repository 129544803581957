import React, { PropsWithChildren } from "react";
import Wrapper from "../Wrapper";
import Styled from "./styled";

interface MainContentProps extends PropsWithChildren<{}> {}

const MainContent: React.FC<MainContentProps> = ({ children }) => {
  return (
    <Styled.MainContent>
      <Wrapper>{children}</Wrapper>
    </Styled.MainContent>
  );
};

export default MainContent;
