import React from "react";
import Input from "../../common/Input/Input";
import Textarea from "../../common/Input/Textarea";

interface JobSubmissionFormContactsProps {
  email?: string;
  comment?: string;

  updateEmail(email: string): void;

  updateComment(comment: string): void;
}

const JobSubmissionFormContacts: React.FC<JobSubmissionFormContactsProps> = ({
  email,
  comment,
  updateEmail,
  updateComment,
}) => {
  return (
    <>
      <Input
        label="E-Mail"
        className="tobospace"
        value={email}
        onValueUpdate={updateEmail}
      />
      <Textarea
        label="Kommentar"
        className="tobospace"
        value={comment}
        onValueUpdate={updateComment}
      />
    </>
  );
};

export default JobSubmissionFormContacts;
