import React, { useCallback } from "react";
import HorizontalScroll from "../../common/HorizontalScroll";
import { goBack } from "../../../utils/url-utils";
import Link from "../../common/Link";
import SvgIcon from "../../common/SvgIcon";

const JobSimilarNavigation: React.FC = () => {
  const handleGoBack = useCallback(() => {
    goBack(window.location.pathname.replace("/similar", ""));
  }, []);

  return (
    <HorizontalScroll>
      <Link onClick={handleGoBack}>
        <SvgIcon name="arrowback" />
      </Link>
    </HorizontalScroll>
  );
};

export default JobSimilarNavigation;
