import React from "react";
import Search from "../Search";
import HorizontalTagsList from "../../common/HorizontalTagsList";
import ThreeColumnLayout from "../../common/layout/ThreeColumnLayout";
import { observer } from "mobx-react";
import { useJobPremiumStore } from "./JobPremiumStore";
import JobPremiumList from "./JobPremiumList";
import TopEmployersWidget from "../../widgets/TopEmployersWidget";
import AdvertisementBannerWidget from "../../widgets/AdvertisementBannerWidget";
import JobsAggregationsWidget from "../../widgets/JobsAggregationsWidget";
import { Tabs, Tab } from "../../common/Tabs";

const JobPremiumPage = observer(function JobPremiumPage() {
  const store = useJobPremiumStore();

  const {
    allJobsCount,
    premiumJobs,
    lastSearches,
    setQuery,
    removeLastSearch,
    clearLastSearches,
  } = store;
  return (
    <>
      <Search onSearch={setQuery} showImage />
      {lastSearches.length > 0 && (
        <HorizontalTagsList
          icon="time"
          label="Letzten Suchanfragen:"
          names={lastSearches}
          onTagClick={setQuery}
          onRemoveTagClick={removeLastSearch}
          onClearAllClick={clearLastSearches}
        />
      )}
      <ThreeColumnLayout
        left={<JobsAggregationsWidget types={["classification", "region"]} />}
        mid={
          <>
            <Tabs
              selectedTabIndex={0}
              onTabSelect={(name) => {
                if (name.startsWith("Alle Stellen")) {
                  window.location.pathname = "/jobs";
                }
              }}
            >
              <Tab name="Premium" />
              <Tab
                name={`Alle Stellen${allJobsCount ? ` (${allJobsCount})` : ""}`}
              />
            </Tabs>
            <JobPremiumList jobs={premiumJobs} />
          </>
        }
        right={
          <>
            <TopEmployersWidget />
            <AdvertisementBannerWidget />
          </>
        }
      />
    </>
  );
});

export default JobPremiumPage;
