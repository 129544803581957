import styled from "styled-components";

const MainContent = styled.div`
  background-color: var(--background-grey);
  overflow: unset;
  padding-bottom: 20px;
`;

const Styled = {
  MainContent,
};

export default Styled;
