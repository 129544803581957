import React from "react";
import JobBox from "../JobBox";
import { Job } from "../types";
import Styled from "./styled";

interface PremiumJobListProps {
  jobs?: Job[];
}

const JobPremiumList: React.FC<PremiumJobListProps> = ({ jobs }) => {
  return (
    <>
      {jobs && jobs.length > 0 && (
        <Styled.JobPremiumList>
          {jobs.map((job) => (
            <JobBox job={job} />
          ))}
        </Styled.JobPremiumList>
      )}
      {!jobs && <div className="center">Loading...</div>}
      {jobs && jobs.length === 0 && (
        <div className="center">Keine offenen Stellen</div>
      )}
    </>
  );
};

export default JobPremiumList;
